/* BASIC GLOWING ANIMATION */
/* https://codepen.io/deepak_96/pen/ExNLEpY */

.glowing::before {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(
    45deg,
    #e8f74d,
    #ff6600d9,
    #00ff66,
    #13ff13,
    #ad27ad,
    #bd2681,
    #6512b9,
    #ff3300de,
    #5aabde
  );
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: -1;
  animation: glower 20s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

/* ADVANCED GLOWING ANIMATION */
/* https://codepen.io/liyrofx/pen/poVZeEG */

.animated-border-box,
.animated-border-box-glow {
  max-height: 200px;
  max-width: 250px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  /* Border Radius */
  border-radius: 10px;
}

.animated-border-box-glow {
  overflow: hidden;
  /* Glow Blur */
  filter: blur(20px);
}

.animated-border-box:before,
.animated-border-box-glow:before {
  content: '';
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 99999px;
  height: 99999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  /*border color, change middle color*/
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    #1976ed,
    rgba(0, 0, 0, 0) 25%
  );
  /* change speed here */
  animation: rotate 4s linear infinite;
}

.animated-border-box:after {
  content: '';
  position: absolute;
  z-index: -1;
  /* border width */
  left: 5px;
  top: 5px;
  /* double the px from the border width left */
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  /*bg color*/
  background: #292a2e;
  /*box border radius*/
  border-radius: 7px;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}
