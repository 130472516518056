.print-only {
  display: none;
}

.screen-only {
  display: flex;
}

@media print {
  .print-only {
    display: flex;
  }

  .screen-only {
    display: none;
  }

  #margin-print-only {
    padding: 30px 40px 20px !important;
  }
}
