.tender-chart {
  .analytic-ov {
    .w-250px {
      margin: 0 auto !important;
      width: 180px !important;
    }

    .mw-100.justify-content-between {
      max-width: 300px !important;
      justify-content: space-around !important;

      div {
        width: 140px !important;
      }
    }
  }
}

@media print {
  .print-only {
    display: flex;
  }

  .screen-only {
    display: none;
  }

  #margin-print-only {
    padding: 30px 40px 20px !important;
  }
}
