#dashboard-edit-content-container {
  padding-top: 16px;
  border-top: solid 2px var(--body_background_light) !important;

  .card.card-selectable {
    padding: 6px 12px;
    cursor: pointer;

    &:hover,
    &.active {
      border: 1px solid var(--primary);
    }

    &:hover {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    &.active {
      background-color: var(--body_background_light);
    }
  }

  .dashboard-edit-content-file-box {
    .nk-block-head-content {
      gap: 20px;
      align-items: center;
    }

    #drag-drop-input-component-id {
      flex: 1;
    }

    .sp-plan-action.card-inner {
      width: auto !important;
    }

    label[for='file-input-component-id'] {
      align-self: center;
      height: 34px;
      padding: 0 32px !important;
      background: none;
      border: 1px solid var(--primary);
      color: var(--primary);

      &:hover {
        background: var(--primary);
        color: var(--white);
      }
    }

    .form-group,
    .nk-block-head-content {
      height: 50px;
    }

    .form-label {
      display: none;
    }
  }
}
