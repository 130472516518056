.nk-content-inner {
  flex-wrap: wrap;
  align-items: flex-start;

  .nk-content-body {
    margin-bottom: 32px;
  }
}

.has-aside .nk-content-body {
  min-height: 64px;
}
