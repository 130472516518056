.tox-statusbar__branding {
  display: none !important;
}
.custom-label {
  font-family: Arial, sans-serif;
  font-size: 12px;
  margin-left: 5px;
}
[aria-label='Insertar merge tag'] {
  visibility: hidden;
}
[aria-label='Insert merge tag'] {
  visibility: hidden;
}

.tox-edit-area {
  max-height: 812px !important;
  max-width: 690px !important;
  margin-left: 125px !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3) !important;
}
.tox-sidebar-wrap {
  background-color: #edeef0 !important;
}
