.simplebar-content-wrapper,
.simplebar-offset,
.simplebar-mask {
  height: 1200px !important;
}

.card-aside {
  width: 350px;
}

.simplebar-mask,
.card-inner-group {
  min-height: 100%;
}

#partner-details--tabs.nav-tabs {
  display: flex;
  justify-content: space-between;
  padding-left: 12px !important;
  padding-right: 12px !important;

  .nav-item {
    padding-right: 12px !important;
  }

  .nav-link .icon + span {
    font-size: 12px;
    margin-left: 0.25rem;
  }
}

.invest-data {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.invest-data-history {
  margin-right: 20px;
  flex-grow: 1;
}

.invest-data-history .title {
  font-size: 11px;
  color: #8094ae;
  text-transform: uppercase;
  margin-bottom: 0.125rem;
  letter-spacing: 0.1em;
}

.invest-data-history .amount {
  font-size: 0.9375rem;
  line-height: 1.1;
  color: #364a63;
}

.invest-data-history .amount span {
  color: #526484;
}

.invest-data-amount {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
}

.invest-data-ck {
  flex-grow: 1;
  height: 48px;
  width: 80px;
  margin-bottom: 0.125rem;
}

@media (min-width: 768px) and (max-width: 1350px), (max-width: 359px) {
  .invest-data {
    margin-top: 0.75rem;
  }
  .invest-data-amount {
    flex-wrap: wrap;
    flex-shrink: 1;
  }
  .invest-data-ck {
    height: 68px;
    width: 140px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .invest-data-history .amount {
    font-size: 0.75rem;
    font-weight: 700;
  }
  .invest-data-history .amount span {
    font-weight: 400;
  }
}

.invest-ov {
  padding: 1.25rem 0 1rem;
}

.invest-ov:last-child {
  padding-bottom: 0;
}

.invest-ov:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}

.invest-ov .subtitle {
  color: #8094ae;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.invest-ov .title {
  font-size: 11px;
  color: #8094ae;
  text-transform: uppercase;
  margin-top: 0.25rem;
  letter-spacing: 0.1em;
}

.invest-ov .amount {
  font-size: 1.25rem;
  line-height: 1.1;
  color: #364a63;
}

.invest-ov .amount span {
  color: #526484;
}

.invest-ov-details {
  display: flex;
}

.invest-ov-info {
  width: 60%;
}

.invest-ov-stats {
  width: 40%;
}

.invest-ov-stats > div {
  display: flex;
  align-items: baseline;
}

.invest-ov-stats .change {
  margin-left: 0.25rem;
}

.invest-top-ck {
  padding-top: 20px;
  height: 70px;
}
