@import 'assets/styles/Fonts.scss';
@import 'assets/styles/CustomFonts.scss';

.print-header {
  font-family: var(--default-font);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;

  h3 {
    font-size: 24px;
    margin-bottom: 0;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
  }

  h6 {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .print-header--title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
