@import './Variables';

/** 07. MISC */
.has-shadow.bg-lighter .card,
.has-shadow.bg-lighter .sp-pdl-item,
.has-shadow.bg-lighter .invoice-wrap,
.has-shadow.bg-lighter .accordion {
  box-shadow: 0 0 8px -3px rgba(60, 77, 98, 0.15);
}

.has-shadow .card,
.has-shadow .sp-pdl-item,
.has-shadow .invoice-wrap,
.has-shadow .accordion {
  box-shadow: 0 0 6px -2px rgba(60, 77, 98, 0.2);
}

.has-shadow .data-head,
.has-shadow .data-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.has-shadow .card .data-head:not(.is-plain) {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  padding-left: 0.75rem;
}

.has-shadow .nav-tabs {
  box-shadow: 0 2px 0 -1px rgba(60, 77, 98, 0.06);
}

.has-shadow .nk-header {
  box-shadow: 0 3px 0 -1px rgba(60, 77, 98, 0.04);
}

.nio-version {
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.02em;
  position: absolute;
  right: -1px;
  bottom: -3px;
}

.ws-pre-wrap {
  white-space: pre-wrap !important;
}

.border-radius-0 {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.code-tag {
  color: #816bff;
}

.code-class {
  color: #ff63a5;
}

.code-fnc {
  color: #09c2de;
}

.nk-switch-icon {
  color: #90a1b8;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nk-switch-icon .icon {
  font-size: 1.5rem;
}

.nk-switch-icon .icon-sm {
  font-size: 1.25rem;
}

.nk-switch-icon:hover {
  color: #526484;
}

.nk-switch-icon.active {
  color: var(--primary);
}

.dark-switch {
  position: relative;
}

.dark-switch:before,
.dark-switch:after {
  position: absolute;
  content: '';
  transform: translateY(-50%);
  border-radius: 1.5rem;
  display: block;
  top: 50%;
}

.dark-switch:before {
  right: 0;
  width: 3rem;
  height: 1.5rem;
  background-color: #fff;
  border: #dbdfea solid 2px;
}

.dark-switch:after {
  height: 1rem;
  width: 1rem;
  right: calc(2rem - 2px - 0.125rem);
  background-color: #dbdfea;
}

.dark-switch.active:before {
  background-color: var(--primary);
  border-color: var(--primary);
}

.dark-switch.active:after {
  right: calc(2px + 0.125rem);
  background-color: #fff;
}

.is-scrollable {
  overflow: auto;
}

.author-list {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.author-list li {
  padding: 0 0.25rem;
}

.author-list li:first-child {
  margin-left: 0.75rem;
}

.author-list.is-grouped li {
  padding: 0;
}

.author-list.is-grouped li:not(:last-child) {
  margin-left: -1rem;
}

.author-list.is-grouped li:first-child {
  margin-left: 0.75rem;
}

.author-list.is-grouped .user-avatar {
  border: 2px solid #fff;
}

.react-list {
  display: flex;
  align-items: center;
  margin: -0.25rem;
  padding: 0;
}

.react-list li {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.3;
  padding: 0.25rem;
}

.react-list .title {
  text-transform: uppercase;
  color: #8094ae;
  letter-spacing: 0.05em;
  font-size: 12px;
  font-weight: 500;
  margin-right: 1rem;
}

.react-list a {
  color: #b7c2d0;
  display: inline-block;
}

.react-list a .icon {
  font-size: 1.5rem;
}

.react-good a:hover {
  color: #1ee0ac;
}

.react-ok a:hover {
  color: var(--primary);
}

.react-bad a:hover {
  color: #f4bd0e;
}

@media (max-width: 359px) {
  .react-list {
    flex-wrap: wrap;
  }
  .react-list .title {
    width: 100%;
  }
}

.profile-ud-label {
  display: block;
  color: #8094ae;
}

.plain .profile-ud-label {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.wider .profile-ud-label {
  display: inline-flex;
  letter-spacing: -0.01em;
  width: 120px;
  flex-shrink: 0;
}

.profile-ud-value {
  color: #526484;
}

.wider .profile-ud-value {
  text-align: right;
  font-weight: 500;
  width: 100%;
}

.profile-ud.wider {
  display: flex;
  padding: 0.5rem 0;
}

.profile-ud-item:nth-child(n + 2) .profile-ud.plain {
  margin-top: 1.25rem;
}

@media (min-width: 768px) {
  .profile-ud.wider {
    padding: 0.75rem 0;
  }
  .profile-ud-list {
    display: flex;
    flex-wrap: wrap;
    margin: -0.25rem -3.25rem;
    max-width: 960px;
  }
  .profile-ud-item {
    width: 50%;
    padding: 0 3.25rem;
  }
  .profile-ud-item:nth-child(2) .profile-ud.plain {
    margin-top: 0;
  }
}

.profile-stats .amount {
  font-size: 1.125rem;
  font-weight: 700;
}

.profile-balance-group {
  display: flex;
  align-items: flex-end;
}

.profile-balance-ck {
  position: relative;
  height: 70px;
  margin-top: 0.5rem;
}

.profile-balance-sub {
  font-size: 0.875rem;
  color: #8094ae;
}

.profile-balance-subtitle {
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

.profile-balance-sub .icon {
  font-size: 0.75rem;
}

.profile-balance-plus {
  position: absolute;
}

.profile-balance-plus .icon {
  font-size: 1rem;
  line-height: 1.5rem;
}

.profile-balance-plus ~ * {
  padding-left: 24px;
}

.profile-balance-amount .number {
  font-size: 1.25rem;
  font-weight: 700;
  color: #364a63;
  line-height: 1.5rem;
  white-space: nowrap;
}

.profile-balance-amount .number small {
  font-weight: 400;
}

.price-plan-media {
  padding: 0.25rem 0;
  margin-bottom: 1.5rem;
}

.price-plan-media img {
  max-width: 90px;
}

.price-plan-info {
  margin-bottom: 1.5rem;
  color: #8094ae;
}

.text-center .price-plan-info {
  padding: 0.25rem 0.5rem;
}

.price-plan-amount {
  font-size: 0.875rem;
  color: #8094ae;
}

.price-plan-amount .amount {
  font-size: 1.5rem;
  font-weight: 700;
  color: #526484;
}

.price-plan-action {
  margin-top: 1.5rem;
  margin-bottom: 0.125rem;
}

.tb-member-item td {
  vertical-align: middle;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tb-member-item:first-child td:first-child {
  border-radius: 4px;
}

.tb-member-item .user-card .user-info {
  flex-grow: 1;
}

.tb-member-role {
  width: 150px;
}

.tb-member-info {
  width: 40%;
}

.tb-member-action {
  text-align: right;
}

.tb-member-action div > .link {
  margin-left: 0.75rem;
}

@media (max-width: 992px) {
  .tb-member-action {
    width: 150px;
  }
}

@media (max-width: 767px) {
  .tb-member-info {
    width: 60%;
  }
}

@media (max-width: 575px) {
  .tb-member-item {
    display: flex;
  }
  .tb-member-info {
    width: auto;
    flex-grow: 1;
  }
  .tb-member-action {
    margin-left: auto;
    width: auto;
  }
  .table .tb-member-body .tb-member-action:last-child {
    padding-right: 0.5rem;
  }
}

@media (max-width: 374px) {
  .tb-member-item .user-card {
    max-width: 200px;
  }
  .tb-member-item .user-card .sub-text {
    width: 96%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.steps ul {
  display: flex;
}

.steps ul li {
  flex-grow: 1;
}

.steps .current-info {
  display: none;
}

.actions ul {
  display: flex;
  margin: -10px;
  padding-top: 20px;
}

.actions ul li {
  padding: 10px;
}

.actions ul li:first-child {
  order: 1;
}

.actions ul li a {
  display: inline-block;
  position: relative;
  color: #fff;
  font-weight: 500;
  transition: all 0.4s ease;
  border-color: var(--primary);
  background: var(--primary);
  padding: 0.4375rem 1.125rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  border-radius: 4px;
}

.actions ul li.disabled {
  display: none;
}

.nk-wizard-head {
  display: none;
}

.nk-wizard-simple .steps {
  margin-bottom: 20px;
}

.nk-wizard-simple .steps ul li {
  position: relative;
  padding-bottom: 5px;
}

.nk-wizard-simple .steps ul li h5 {
  border: none;
  padding: 0 0 6px 0;
  letter-spacing: 0.02em;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  color: #8094ae;
}

.nk-wizard-simple .steps ul li .number {
  font-size: 13px;
  color: #8094ae;
  font-weight: 700;
}

.nk-wizard-simple .steps ul li:after {
  position: absolute;
  height: 2px;
  width: 0;
  left: 0;
  bottom: 0;
  background: var(--primary);
  content: '';
  transition: all 0.4s;
}

.nk-wizard-simple .steps ul li.done:after,
.nk-wizard-simple .steps ul li.current:after {
  width: 100%;
}

.nk-wizard-simple .steps ul li.done h5,
.nk-wizard-simple .steps ul li.done .number,
.nk-wizard-simple .steps ul li.current h5,
.nk-wizard-simple .steps ul li.current .number {
  color: var(--primary);
}

.nk-wizard-simple .steps ul li.current ~ .done:after {
  width: 0;
}

.nk-wizard-simple .steps ul li.current ~ .done h5,
.nk-wizard-simple .steps ul li.current ~ .done .number {
  color: #8094ae;
}

.nk-wizard-simple .steps .current-info {
  display: none;
}

.tb-tnx-head {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  background: #f5f6fa;
}

.tb-tnx-head td,
.tb-tnx-head th {
  color: #8094ae;
  padding: 0.625rem 0.25rem;
}

.tb-tnx-head th {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tb-tnx-item {
  font-size: 14px;
}

.tb-tnx-item td {
  padding: 1.25rem 0.25rem;
  vertical-align: middle;
}

.is-compact .tb-tnx-item td {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.tb-tnx-id a {
  display: block;
}

.tb-tnx-id span {
  font-weight: 500;
}

.tb-tnx-item .tb-tnx-total {
  font-weight: 500;
  color: #526484;
}

.tb-tnx-desc {
  padding-right: 0.5rem;
}

.tb-tnx-item .tb-tnx-date {
  color: #8094ae;
}

.tb-tnx-item .tb-tnx-date .date {
  font-size: 13px;
}

.tb-tnx-action {
  width: 60px;
  text-align: right;
}

.tb-tnx-action .dropdown {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.tb-tnx-btns .btn + .btn {
  margin-left: 0.5rem;
}

.tb-tnx-btns + .dropdown {
  margin-left: 1rem;
}

@media (max-width: 767px) {
  .tb-tnx-item,
  .tb-tnx-head {
    display: flex;
    width: 100%;
  }
  .tb-tnx-item th,
  .tb-tnx-head th {
    flex-grow: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .tb-tnx-item td,
  .tb-tnx-head td {
    flex-grow: 1;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  .tb-tnx-item {
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .tb-tnx-item:not(:last-child) {
    border-bottom: 1px solid #e5e9f2;
  }
  .tb-tnx-amount {
    width: 110px;
    text-align: right;
  }
  .tb-tnx-id,
  .tb-tnx-head th:first-child {
    min-width: 85px;
    padding-right: 0.25rem !important;
    flex-grow: 0 !important;
  }
  .tb-tnx-info {
    width: 45%;
  }
  .tb-tnx-date .date {
    font-size: 12px;
  }
  .tb-tnx-date .date + .date {
    padding-left: 0.5rem;
  }
  .tb-tnx-action:last-child {
    flex-grow: 0;
    padding-left: 0.5rem;
    display: inline-flex;
    align-items: center;
  }
}

@media (max-width: 575px) {
  .tb-tnx-item {
    font-size: 13px;
  }
  .tb-tnx-id {
    width: 100%;
  }
  .tb-tnx-id + td {
    padding-left: 1.25rem;
  }
  .tb-tnx-info {
    width: 65%;
  }
  .tb-tnx-amount:last-child {
    width: 25%;
  }
  .tb-tnx-amount:not(:last-child) {
    display: none;
  }
  .tb-tnx-amount.is-alt {
    position: absolute;
    display: inline-flex;
    right: 1.25rem;
    width: auto;
  }
  .tb-tnx-amount.is-alt .tb-tnx-total {
    order: 5;
    padding-left: 1.25rem;
  }
}

@media (min-width: 768px) {
  .tb-tnx-desc,
  .tb-tnx-date,
  .tb-tnx-total,
  .tb-tnx-status {
    display: inline-block;
    vertical-align: middle;
  }
  .tb-tnx-desc,
  .tb-tnx-date {
    width: calc(50% - 4px);
  }
  .tb-tnx-total {
    width: calc(60% - 4px);
  }
  .tb-tnx-status {
    width: calc(40% - 4px);
  }
  .tb-tnx-amount {
    width: 25%;
  }
  .tb-tnx-date .date,
  .tb-tnx-date > span > span {
    width: calc(50% - 4px);
    display: inline-block;
  }
  .tb-tnx-date .date + .date,
  .tb-tnx-date > span > span + span {
    padding-left: 0.75rem;
  }
}

.tb-odr-item {
  font-size: 14px;
}

.tb-odr-item td {
  padding: 1rem 0.25rem;
  vertical-align: middle;
}

.tb-odr-id,
.tb-odr-total {
  display: block;
}

.tb-odr-item .tb-odr-id,
.tb-odr-item .tb-odr-total {
  font-weight: 500;
}

.tb-odr-id a {
  display: block;
}

.tb-odr-id span {
  font-weight: 500;
}

.tb-odr-item .tb-odr-total {
  color: #364a63;
}

.tb-odr-head .tb-odr-item .tb-odr-total {
  color: inherit;
}

.tb-odr-item .tb-odr-date {
  color: #8094ae;
}

.tb-odr-head .tb-odr-item .tb-odr-date {
  color: inherit;
}

.tb-odr-action > div,
.tb-odr-action > .btn {
  vertical-align: middle;
}

.tb-odr-action:last-child {
  padding-left: 1.25rem;
  text-align: right;
}

.tb-odr-btns .btn + .btn {
  margin-left: 0.5rem;
}

.tb-odr-id {
  min-width: 90px;
}

.tb-odr-head {
  background: #f5f6fa;
}

.tb-odr-head th {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: #8094ae;
  padding: 0.625rem 0.25rem;
}

@media (max-width: 767px) {
  .tb-odr-item {
    display: flex;
  }
  .tb-odr-item th,
  .tb-odr-item td {
    flex-grow: 1;
  }
  .tb-odr-amount {
    text-align: right;
  }
}

@media (max-width: 575px) {
  .tb-odr-item {
    font-size: 13px;
  }
  .tb-odr-amount {
    width: 24%;
  }
  .tb-odr-info {
    width: 44%;
  }
  .tb-odr-action {
    width: 2rem;
    min-width: auto;
  }
  .tb-odr-item .tb-odr-action {
    padding-left: 0.5rem;
  }
}

@media (min-width: 768px) {
  .tb-odr-info {
    min-width: 55%;
  }
  .tb-odr-amount {
    width: 30%;
  }
  .tb-odr-total {
    min-width: 100px;
  }
  .tb-odr-id,
  .tb-odr-total {
    display: inline-block;
  }
  .tb-odr-id + span,
  .tb-odr-total + span {
    padding-left: 1rem;
  }
  .tb-odr-btns + .dropdown,
  .tb-odr-btns + .btn {
    margin-left: 0.75rem;
  }
}

@media (min-width: 992px) {
  .tb-odr-id {
    min-width: 120px;
  }
  .tb-odr-total {
    min-width: 50%;
  }
}

.tb-ftr-head th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.table .tb-ftr-head th {
  font-size: 12px;
  color: #8094ae;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.12em;
}

.tb-ftr-item td {
  border-top: 0;
}

.tb-ftr-info {
  font-weight: 500;
  width: 60%;
}

.tb-ftr-plan {
  text-align: center;
}

.tb-ftr-plan .plan-name {
  display: none;
}

.tb-ftr-plan .icon {
  color: var(--primary);
}

@media (max-width: 575px) {
  .tb-ftr-item {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #e5e9f2;
    padding: 0.75rem 1.25rem;
  }
  .tb-ftr-head .tb-ftr-item {
    padding: 0;
  }
  .tb-ftr-item:first-child {
    border-top: 0;
  }
  .table .tb-ftr-item td {
    padding: 0.25rem 0;
  }
  .table .tb-ftr-item .tb-ftr-plan {
    padding-right: 1.25rem;
  }
  .tb-ftr-info {
    width: 100%;
  }
  .tb-ftr-plan .icon {
    font-size: 1rem;
    vertical-align: middle;
    margin-right: 0.25rem;
  }
  .tb-ftr-plan .plan-name {
    display: inline;
    vertical-align: middle;
    line-height: 1.3;
    font-size: 12px;
  }
  .tb-ftr-head .tb-ftr-plan {
    display: none;
  }
}

@media (min-width: 575px) {
  .tb-ftr-item:first-child td {
    padding-top: 1.25rem;
  }
  .tb-ftr-item:last-child td {
    padding-bottom: 1.25rem;
  }
}

.is-compact .tb-tnx-item td,
.is-compact .tb-odr-item td {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

@media (max-width: 767px) {
  .is-compact .tb-tnx-item,
  .is-compact .tb-odr-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .is-compact .tb-tnx-item td,
  .is-compact .tb-odr-item td {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.nk-tb-list {
  display: table;
  width: 100%;
  font-size: 13px;
  color: #8094ae;
}

.nk-tb-list.is-alt {
  color: #526484;
}

.nk-tb-list .list-status,
.nk-tb-list .tb-status {
  font-size: 12px;
  font-weight: 500;
}

.nk-tb-list .tb-lead,
.nk-tb-list .tb-amount {
  font-weight: 500;
  color: #364a63;
  display: block;
  line-height: 1.4;
}

.nk-tb-list .tb-lead-sub,
.nk-tb-list .tb-amount-sub {
  color: #526484;
  display: block;
  line-height: 1.4;
}

.nk-tb-list .tb-sub,
.nk-tb-list .tb-amount-sm {
  font-size: 0.9em;
}

.nk-tb-list .tb-amount span,
.nk-tb-list .tb-amount .currency {
  color: #526484;
  font-weight: 400;
}

.nk-tb-list .user-info .tb-lead + span,
.nk-tb-list .tb-lead + .tb-date {
  font-size: 11px;
}

.nk-tb-list .tb-country {
  display: flex;
  align-items: center;
}

.nk-tb-list .tb-country .name {
  color: #364a63;
}

.nk-tb-list .tb-country .flag {
  height: 16px;
  border-radius: 3px;
}

.nk-tb-list .tb-country .flag + .name {
  margin-left: 0.6875rem;
}

.nk-tb-list .tb-product {
  display: flex;
  align-items: center;
}

.nk-tb-list .tb-product .thumb {
  width: 48px;
  border-radius: 4px;
  margin-right: 1rem;
}

.nk-tb-list .tb-product .title {
  font-weight: 700;
  font-size: 0.875rem;
  color: #364a63;
}

.nk-tb-list .tb-asterisk {
  font-size: 1.125rem;
  line-height: 1;
  vertical-align: middle;
}

.nk-tb-list .tb-asterisk a {
  color: var(--primary);
}

.nk-tb-item {
  transition: background-color 0.3s, box-shadow 0.3s;
  display: table-row;
}

.nk-tb-item:not(.nk-tb-head):hover,
.nk-tb-item:not(.nk-tb-head).seleted {
  background: #f8f9fc;
  box-shadow: 0 0 10px -4px rgba(54, 74, 99, 0.2);
}

.nk-tb-col {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 1rem 0.5rem;
}

.nk-tb-col:first-child {
  padding-left: 1.25rem;
}

.nk-tb-col:last-child {
  padding-right: 1.25rem;
}

.nk-tb-col-tools {
  max-width: 3.75rem;
}

.nk-tb-col-check {
  width: 3rem;
}

.nk-tb-col-check .custom-control {
  vertical-align: middle;
}

.nk-tb-item:not(:last-child) .nk-tb-col {
  border-bottom: 1px solid #dbdfea;
}

.nk-tb-head .nk-tb-col {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #8094ae;
  font-size: 0.9em;
  border-bottom: 1px solid #dbdfea;
}

.nk-tb-col-action {
  text-align: right;
}

.nk-tb-col-action > .dropdown:last-child {
  right: -0.5rem;
}

.nk-tb-col-nosort:before,
.nk-tb-col-nosort:after {
  display: none !important;
}

.nk-tb-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.nk-tb-ulist .nk-tb-actions,
.nk-tb-actions-adj .nk-tb-actions {
  right: -0.5rem;
}

.nk-tb-actions.visible1 {
  width: 1rem;
}

.nk-tb-action-hidden {
  opacity: 0;
  transition: 0.3s ease;
}

.nk-tb-item:hover .nk-tb-action-hidden {
  opacity: 1;
  background: #f8f9fc;
}

.nk-tb-list .nk-tb-head .btn-trigger .icon {
  font-size: 1.5em;
}

.nk-tb-list.is-separate {
  margin-top: -8px;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.nk-tb-list.is-separate .nk-tb-item > .nk-tb-col {
  background: #fff;
  border: none;
  box-shadow: 0px 1px 3px 0px rgba(54, 74, 99, 0.05);
}

.nk-tb-list.is-separate .nk-tb-item > .nk-tb-col:first-child {
  border-radius: 4px 0 0 4px;
}

.nk-tb-list.is-separate .nk-tb-item > .nk-tb-col:last-child {
  border-radius: 0 4px 4px 0;
}

.nk-tb-list.is-separate .nk-tb-item:hover .nk-tb-action-hidden {
  background: #fff;
}

.nk-tb-list.is-separate .nk-tb-head .nk-tb-col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .nk-tb-col-check {
    width: 3.25rem;
  }
  .nk-tb-item .nk-tb-col:first-child {
    padding-left: 1.5rem;
  }
  .nk-tb-item .nk-tb-col:last-child {
    padding-right: 1.5rem;
  }
}

@media (min-width: 1540px) {
  .nk-tb-list {
    font-size: 14px;
  }
  .nk-tb-list .list-status,
  .nk-tb-list .tb-status {
    font-size: 13px;
  }
  .nk-tb-list .user-info .tb-lead + span,
  .nk-tb-list .tb-lead + .tb-date {
    font-size: 12px;
  }
}

.is-loose .nk-tb-item:not(.nk-tb-head) .nk-tb-col {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.is-medium .nk-tb-item:not(.nk-tb-head) .nk-tb-col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.is-compact .nk-tb-item:not(.nk-tb-head) .nk-tb-col {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.card-full .nk-wg1 {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nk-wg1-block {
  display: flex;
  padding: 1.25rem;
  align-items: flex-start;
  flex-direction: column;
}

.nk-wg1-img {
  width: 84px;
  margin-right: 0;
  margin-bottom: 1.5rem;
  flex-shrink: 0;
  text-align: center;
  padding: 0.125rem;
}

.nk-wg1-text {
  color: #8094ae;
}

.nk-wg1-action {
  margin-top: auto;
  border-top: 1px solid #e5e9f2;
}

.nk-wg1-action .link {
  position: relative;
  display: block;
  line-height: 1.3;
  padding: 1.25rem;
  padding-right: 2.5rem;
  white-space: inherit;
}

.nk-wg1-action .link:hover {
  background-color: #f5f6fa;
}

.nk-wg1-action .link .icon {
  position: absolute;
  right: 1.25rem;
  margin-right: -0.25rem;
  margin-top: -1px;
}

.nk-wg1-action .link-inline {
  display: inline-block;
  padding-right: 0.25rem;
}

.nk-wg1-action .link-inline .icon {
  position: static;
  padding-left: 0.25rem;
  margin-right: 0;
}

@media (min-width: 576px) {
  .nk-wg1-block {
    padding: 1.5rem;
  }
  .nk-wg1-action .link {
    padding: 1.5rem;
    padding-right: 2.75rem;
  }
  .nk-wg1-action .link-inline {
    padding-right: 0.25rem;
  }
}

@media (min-width: 1200px) {
  .nk-wg1-img {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
  .nk-wg1-block {
    flex-direction: row;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.nk-help {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.nk-help-img {
  width: 120px;
  margin-right: 0;
  margin-bottom: 1.5rem;
  flex-shrink: 0;
  text-align: center;
  padding: 0.125rem;
}

.text-center .nk-help-img {
  margin-left: auto;
  margin-right: auto;
}

.nk-help-action {
  flex-shrink: 0;
  margin-top: 1.5rem;
  width: 100%;
  margin-bottom: 0.125rem;
}

@media (min-width: 768px) {
  .nk-help {
    flex-wrap: nowrap;
  }
  .nk-help-img {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
  .card-inner-lg .nk-help-img {
    margin-left: -0.5rem;
  }
  .nk-help-text {
    flex-grow: 1;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
  }
  .nk-help-action {
    width: auto;
    margin-top: 0;
    padding-left: 1.5rem;
  }
}

.card-full .nk-help-plain {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nk-help-plain .nk-help-img {
  margin-bottom: 1.25rem;
}

.nk-help-plain .nk-help-text {
  padding: 0;
}

.nk-help-plain .nk-help-text:not(:last-child) {
  margin-bottom: 1.5rem;
}

.nk-help-plain .nk-help-action {
  margin-top: auto;
  padding: 0;
}

.nk-cta {
  display: flex;
  align-items: center;
}

.nk-cta-block {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.nk-cta-img {
  flex-shrink: 0;
}

.nk-cta-img .icon {
  width: 2.25rem;
  height: 2.25rem;
  font-size: 1rem;
}

.nk-cta-text {
  flex-grow: 1;
  line-height: 1.5;
  font-family: var(--default-font), sans-serif;
  font-weight: 700;
  font-size: 0.9375rem;
  padding: 0.25rem 1.5rem 0.25rem 1.25rem;
}

.nk-cta-action {
  flex-shrink: 0;
  margin-left: auto;
}

@media (max-width: 459px) {
  .nk-cta {
    flex-wrap: wrap;
  }
  .nk-cta-text {
    padding-right: 0;
    font-size: 0.875rem;
  }
  .nk-cta-action {
    flex-shrink: 1;
    width: 100%;
    margin: 1rem 0 0.5rem 3.5rem;
  }
}

.nk-cta-plain .nk-cta-block {
  display: block;
}

.nk-cta-plain .nk-cta-img {
  margin-bottom: 1rem;
}

.nk-cta-plain .nk-cta-text {
  font-weight: 400;
  padding: 0.25rem 0;
}

.nk-cta-plain .nk-cta-action {
  margin-top: 1.25em;
}

.nk-kyc-app-icon {
  margin-bottom: 2rem;
}

.nk-kyc-app-icon .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  font-size: 36px;
  border-radius: 50%;
  color: #8094ae;
  border: 2px solid #dbdfea;
}

.nk-kyc-app-text:not(:last-child) {
  margin-bottom: 2rem;
}

.nk-kycfm-head {
  display: flex;
  align-items: center;
}

.nk-kycfm-head,
.nk-kycfm-content,
.nk-kycfm-footer {
  padding: 1.5rem;
}

.nk-kycfm-head:not(:last-child),
.nk-kycfm-content:not(:last-child),
.nk-kycfm-footer:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}

.nk-kycfm-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  font-size: 16px;
  border-radius: 50%;
  color: #526484;
  border: 2px solid #dbdfea;
  margin-right: 1rem;
  flex-shrink: 0;
}

.nk-kycfm-title .title {
  margin: 0.25rem 0;
}

.nk-kycfm-content .title {
  margin-bottom: 0.75rem;
}

.nk-kycfm-note {
  color: #8094ae;
  display: flex;
}

.nk-kycfm-note:not(:last-child) {
  margin-bottom: 1rem;
}

.nk-kycfm-note .icon {
  margin-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.65;
}

.nk-kycfm-label {
  display: flex;
  align-items: center;
  border: 2px solid #e5e9f2;
  border-radius: 4px;
  padding: 0.5rem 2.875rem 0.5rem 1rem;
  font-size: 13px;
  line-height: 1.3;
  letter-spacing: -0.01rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 0;
}

.nk-kycfm-label::after {
  position: absolute;
  right: 1.375rem;
  top: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 12px;
  border-radius: 50%;
  transform: translateY(-50%);
  font-family: 'Nioicon';
  content: '';
  color: #fff;
  background: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.nk-kycfm-label-icon {
  position: relative;
  width: 34px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 12px;
}

.nk-kycfm-label-icon .label-icon {
  transition: all 0.3s;
  color: #8094ae;
  width: 100%;
  height: 28px;
}

.nk-kycfm-control {
  position: absolute;
  height: 1px;
  width: 1px;
  opacity: 0;
}

.nk-kycfm-control-list {
  display: flex;
  flex-wrap: wrap;
}

.nk-kycfm-control-list + * {
  margin-top: 1.25rem;
}

.nk-kycfm-control-item {
  position: relative;
  flex-grow: 1;
}

.nk-kycfm-control:checked ~ .nk-kycfm-label {
  border-color: var(--primary);
}

.nk-kycfm-control:checked ~ .nk-kycfm-label:after {
  opacity: 1;
}

.nk-kycfm-control:checked ~ .nk-kycfm-label .label-icon {
  color: var(--primary);
}

.nk-kycfm-upload:not(:first-child) {
  margin-top: 1.5rem;
}

@media (min-width: 576px) {
  .nk-kycfm-head,
  .nk-kycfm-content,
  .nk-kycfm-footer {
    padding: 1.5rem 2.25rem;
  }
  .nk-kycfm-content {
    padding-bottom: 1.75rem;
  }
  .nk-kycfm-footer {
    padding: 2.25rem;
  }
}

.nk-sale-data {
  flex-shrink: 0;
}

.nk-sale-data-group {
  display: flex;
  flex-wrap: wrap;
}

.nk-sale-data .amount {
  display: block;
  font-size: 1.75rem;
  color: #364a63;
}

.nk-sale-data .amount .change {
  font-size: 0.875rem;
}

.nk-sale-data .amount.sm {
  font-size: 1.125rem;
  color: #8094ae;
}

.nk-sale-data .sub-title {
  color: #8094ae;
  font-size: 12px;
}

.nk-sale-data .sub-title .change {
  margin-right: 0.5rem;
}

.nk-sales-ck {
  height: 80px;
  flex-grow: 1;
}

.nk-sales-ck.large {
  height: 184px;
}

@media (min-width: 1200px) {
  .nk-sales-ck.sales-revenue {
    height: 164px;
  }
}

.nk-activity-item {
  display: flex;
  align-items: center;
  padding: 1.25rem;
}

.nk-activity-item:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}

.nk-activity-data {
  margin-left: 1rem;
}

.nk-activity-data .time {
  display: block;
  font-size: 12px;
  color: #8094ae;
  line-height: 1.3;
}

@media (min-width: 576px) {
  .nk-activity-item {
    display: flex;
    padding: 1rem 1.5rem;
  }
}

.nk-support-item {
  display: flex;
  padding: 1.25rem;
}

.nk-support-item:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}

.nk-support-content {
  flex-grow: 1;
  margin-left: 1rem;
}

.nk-support-content .title {
  font-size: 0.875rem;
  font-weight: 700;
  color: #364a63;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nk-support-content p {
  margin-bottom: 0;
  margin-top: 0.25rem;
}

.nk-support-content .time {
  display: block;
  font-size: 12px;
  color: #8094ae;
}

@media (min-width: 576px) {
  .nk-support-item {
    display: flex;
    padding: 1.25rem 1.5rem;
  }
}

.device-status {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.device-status-ck {
  height: 200px;
  margin: auto 0;
}

.device-status-group {
  display: flex;
  justify-content: space-between;
  padding-top: 1.75rem;
  margin: auto;
  width: 280px;
  max-width: 100%;
}

.device-status-data > .icon {
  font-size: 1.5rem;
}

.device-status-data .title {
  font-size: 12px;
  color: #8094ae;
  margin-bottom: 0.5rem;
}

.device-status-data .amount {
  font-size: 1.125rem;
  color: #364a63;
  font-weight: 500;
}

.device-status-data .change {
  margin-left: -0.125rem;
}

.traffic-channel {
  margin-top: 1rem;
}

.traffic-channel-ck {
  height: 44px;
  width: 130px;
  margin-top: -4px;
  margin-bottom: -4px;
}

.traffic-channel-doughnut-ck {
  height: 160px;
}

.traffic-channel-group {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.75rem;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 320px;
  max-width: 100%;
}

.traffic-channel-data {
  width: 50%;
}

.traffic-channel-data .title {
  display: flex;
  align-items: center;
  color: #8094ae;
  font-size: 12px;
}

.traffic-channel-data .title .dot {
  margin-right: 0.5rem;
}

.traffic-channel-data .amount {
  font-size: 1.125rem;
  color: #364a63;
  margin-left: 1.25rem;
}

.traffic-channel-data .amount small {
  font-size: 12px;
  color: #8094ae;
}

@media (max-width: 575.98px) {
  .traffic-channel-ck {
    width: 100%;
  }
  .traffic-channel-table .nk-tb-item {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e5e9f2;
    padding: 1.125rem 0;
  }
  .traffic-channel-table .nk-tb-col {
    border-bottom: none !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .traffic-channel-table .nk-tb-channel {
    width: 50%;
  }
  .traffic-channel-table .nk-tb-sessions {
    width: 25%;
  }
  .traffic-channel-table .nk-tb-prev-sessions {
    display: none;
  }
  .traffic-channel-table .nk-tb-change {
    width: 25%;
  }
  .traffic-channel-table .nk-tb-trend {
    width: 100%;
    padding-left: 1.25rem;
  }
}

@media (min-width: 1540px) and (max-width: 1800px) {
  .traffic-channel-data .amount {
    font-size: 1rem;
  }
}

.analytic-data-group {
  display: flex;
  flex-wrap: wrap;
}

.analytic-data .title {
  font-size: 12px;
  font-weight: 500;
  color: #8094ae;
  margin-bottom: 0.375rem;
}

.analytic-data .title span {
  font-weight: 400;
}

.analytic-data .amount {
  color: #364a63;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 0.25rem;
}

.analytic-data .amount-sm {
  font-size: 1.125rem;
}

.analytic-data .change {
  font-size: 13px;
  margin-left: -0.125rem;
}

.analytic-data .subtitle {
  font-size: 12px;
  color: #8094ae;
}

@media (min-width: 1540px) and (max-width: 1800px) {
  .analytic-data .amount {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .analytic-data .amount-sm {
    font-size: 1.125rem;
  }
}

.analytic-ov-data {
  width: 50%;
}

.analytic-ov-ck {
  height: 175px;
  margin-top: 2rem;
}

@media (min-width: 480px) {
  .analytic-ov-data {
    width: 25%;
  }
}

.analytic-au-data {
  width: 33.3333%;
}

.analytic-au-ck {
  height: 170px;
  margin-top: 2.25rem;
}

.analytic-wp-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.analytic-wp-ck {
  height: 36px;
}

.analytic-wp-graph {
  width: 150px;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.analytic-wp-graph .title {
  margin-bottom: 0;
}

.analytic-wp-text {
  text-align: right;
  margin-left: 0.5rem;
}

@media (min-width: 420px) and (max-width: 767px) {
  .analytic-wp-graph {
    width: 60%;
  }
}

@media (min-width: 1540px) and (max-width: 1800px) {
  .analytic-wp-graph {
    width: 120px;
  }
}

.chart-label {
  font-size: 12px;
  color: #8094ae;
}

.chart-label-group {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.analytics-map .vector-map {
  height: 160px;
  margin: 1rem 0;
}

.analytics-map-data {
  font-size: 0.875rem;
}

.analytics-map-data td {
  padding: 0.25rem 0;
}

.analytics-map-data .country {
  color: #8094ae;
}

.analytics-map-data .amount {
  color: #364a63;
  text-align: right;
}

.analytics-map-data .percent {
  text-align: right;
  color: #8094ae;
}

.analytics-map-data-list {
  width: 100%;
  margin-bottom: -0.25rem;
}

.card-title .subtitle {
  color: #8094ae;
  font-size: 13px;
  font-weight: 500;
}

.card-amount {
  display: flex;
  align-items: baseline;
}

.card-amount .change {
  margin-left: 0.5rem;
}

.card-amount .amount {
  font-size: 1.5rem;
  color: #364a63;
}

.card-amount .amount span {
  color: #526484;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .card-amount .amount {
    font-size: 1rem;
    font-weight: 500;
  }
  .card-amount .amount span {
    font-weight: 400;
  }
  .card-amount .change {
    font-size: 12px;
  }
}

.card-inner .nav-tabs-card {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

@media (min-width: 576px) {
  .card-inner .nav-tabs-card {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

.nav-tabs-xs .nav-link {
  padding: 0.75rem 0;
  font-size: 13px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .nav-tabs-xs .nav-item {
    padding-right: 1.25rem;
  }
}

@media (min-width: 992px) {
  .nav-tabs-sm .nav-item {
    padding-right: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .nav-tabs-sm .nav-item {
    padding-right: 1.25rem;
  }
}
