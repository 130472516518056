#board-partner-delegations {
  .represented-selector-wrapper {
    height: 74px;
  }

  .instructions-table-wrapper {
    min-height: 200px;
    max-height: 556px;
    overflow-y: auto;

    .placeholder-row {
      height: 200px;
    }

    img {
      width: 150px;
      height: 150px;
    }

    em {
      font-size: 17px;
    }
  }
}
