@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  --ckeditor5-preview-sidebar-width: 270px;
  --ckeditor5-preview-height: 580px;
  font-family: 'Lato';
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.ck-content {
  font-family: 'Lato';
  word-break: break-word;
  line-height: initial !important;

  p {
    margin-bottom: initial !important;
  }
}

.editor-container__editor-wrapper {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
}

.editor-container_document-editor {
  border: 1px solid var(--ck-color-base-border);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor-container_document-editor .editor-container__toolbar {
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
}

.editor-container_document-editor .editor-container__toolbar > .ck.ck-toolbar {
  width: 100%;
  border-radius: 0;
  border: 0;
}

.editor-container_document-editor .editor-container__editor-wrapper {
  max-height: var(--ckeditor5-preview-height);
  min-height: var(--ckeditor5-preview-height);
  overflow-y: scroll;
  display: flex;
  flex-grow: 1;
  background: var(--ck-color-base-foreground);
}

.editor-container__editor {
  flex-grow: 1;
  flex-direction: column;
  margin-top: 28px;
  margin-bottom: 28px;
  padding: 0 10px;
}

.editor-container_document-editor {
  display: flex;
  justify-content: center;
}

.editor-container_document-editor
  .editor-container__editor
  .ck.ck-editor__editable {
  box-sizing: border-box;
  min-width: 0;
  max-width: calc(210mm + 2px);
  min-height: 297mm;
  height: fit-content;
  padding: 20mm 12mm;
  border: 1px hsl(0, 0%, 82.7%) solid;
  background: hsl(0, 0%, 100%);
  box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
  flex: 1 1 auto;
  margin-left: 72px;
  margin-right: 72px;
}

.editor-container_include-outline
  .editor-container__editor
  .ck.ck-editor__editable {
  margin-left: 0;
}

.editor-container_include-annotations
  .editor-container__editor
  .ck.ck-editor__editable {
  margin-right: 0;
}

.editor-container__sidebar,
.editor-container__sidebar__fullscreen {
  min-width: 50px;
  max-width: 100%;
  margin: 28px 10px;
  flex-shrink: 0; /* Prevent shrinking of sidebar */
}

.revision-history {
  display: none;
}

.revision-history__wrapper {
  display: flex;
  flex-grow: 1;
}

.revision-history__wrapper .ck.ck-editor {
  margin: 0;
  width: 100%;
}

.revision-history__wrapper .revision-history__sidebar {
  border: 1px solid var(--ck-color-base-border);
  border-left: 0;
  min-height: 100%;
}

.ck-content .table thead {
  display: table-row-group;
}

.ck-content .table tr {
  break-inside: avoid;
  break-after: auto;
}

.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_s.ck-dropdown__panel-visible,
.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_sw.ck-dropdown__panel-visible,
.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se.ck-dropdown__panel-visible {
  display: flex;
  flex-direction: column;
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
}

.ck.ck-dropdown
  .ck-dropdown__panel.ck-dropdown__panel-visible
  .ck-button.ck-button_with-text.ck-disabled {
  cursor: not-allowed;
  color: #000000;
  background-color: #f8f8f8;
  font-weight: 600;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid #f8f8f8;
}
