.email-signature-form {
  .double-row-first-item {
    width: 48% !important;
    float: left !important;
  }

  .double-row-last-item {
    width: 48% !important;
    float: right !important;
  }
}
