.custom-forms-wrapper {
  .custom-file-input ~ .error,
  .custom-file-input ~ .invalid,
  .dual-listbox .dual-listbox__search ~ .error,
  .dual-listbox .dual-listbox__search ~ .invalid,
  .form-control ~ .error,
  .form-control ~ .invalid,
  .form-select ~ .error,
  .form-select ~ .invalid,
  .input-group ~ .error,
  .input-group ~ .invalid,
  .js-select2 ~ .error,
  .js-select2 ~ .invalid,
  div.dataTables_wrapper div.dataTables_filter input ~ .error,
  div.dataTables_wrapper div.dataTables_filter input ~ .invalid {
    color: #e85347;
    font-size: 11px;
    font-style: italic;
  }

  .custom-btn-check {
    position: relative;
  }

  .custom-btn-check input {
    position: absolute;
    opacity: 0;
    height: 1px;
    width: 1px;
  }

  .custom-btn-check input:checked ~ label {
    color: #fff;
    border-color: var(--primary);
    background-color: var(--primary);
  }

  .custom-btn-check label {
    display: inline-block;
    border: 2px solid #e5e9f2;
    background-color: #e5e9f2;
    font-weight: 500;
    color: #526484;
    text-align: center;
    padding: 0.4375rem 1.125rem;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    border-radius: 4px;
    transition: all 0.3s;
    margin-bottom: 0 !important;
  }

  .custom-btn-sm label {
    padding: 0.25rem 1rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .custom-btn-round label {
    border-radius: 60px;
  }

  .custom-btn-outline label {
    background-color: transparent;
  }

  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .dual-listbox .dual-listbox__search,
  .form-control,
  div.dataTables_wrapper div.dataTables_filter input {
    min-height: calc(2.125rem + 2px);
  }

  .custom-control {
    position: relative;
    z-index: 1;
    display: inline-flex;
    min-height: 1.5rem;
    padding-left: 2.25rem;
  }

  .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
  }

  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.5rem;
    height: 1.471875rem;
    opacity: 0;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--primary);
    background-color: var(--primary);
    box-shadow: none;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 3px rgba(101, 118, 255, 0.1);
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary);
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: none;
  }

  .custom-control-input:disabled ~ .custom-control-label,
  .custom-control-input[disabled] ~ .custom-control-label {
    color: #3c4d62;
  }

  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }

  .custom-control-label:after,
  .custom-control-label:before {
    z-index: 1;
  }

  .custom-control-label::before {
    position: absolute;
    top: -0.028125rem;
    left: -2.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    content: '';
    background-color: #fff;
    border: #dbdfea solid 2px;
    box-shadow: inset 0 1px 1px rgba(16, 25, 36, 0.075);
    border-radius: 4px;
  }

  .custom-control-label::after {
    position: absolute;
    top: -0.028125rem;
    left: -2.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    content: '';
    background: no-repeat 50%/50% 50%;
  }

  .custom-control {
    display: inline-flex;
    min-height: 1.5rem;
  }

  .custom-control.color-control {
    padding: 4px !important;
  }

  .custom-control.color-control .custom-control-label::before {
    opacity: 0;
    display: block;
    left: -5px;
    right: -4px;
    top: -5px;
    bottom: -4px;
    border-radius: 50%;
    border: 2px solid var(--primary) !important;
    background: 0 0 !important;
    transition: 0.3s ease;
  }

  .custom-control.color-control .custom-control-label::after {
    display: none;
  }

  .custom-control.color-control
    .custom-control-input:checked
    ~ .custom-control-label::before {
    opacity: 1;
  }

  .custom-control.notext {
    margin-left: -0.75rem;
  }

  .custom-control.notext .custom-control-label {
    position: relative;
    left: 0.75rem;
  }

  .custom-control-label {
    font-size: 14px;
    line-height: 1.25rem;
    padding-top: 0.125rem;
  }

  .custom-control-label::after,
  .custom-control-label::before {
    top: 0;
  }

  .custom-control-label:after {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-image: none !important;
    font-family: Nioicon;
    color: #fff;
    opacity: 0;
  }

  .custom-checkbox .custom-control-label:after {
    content: '\e9bd';
  }

  .custom-radio .custom-control-label:after {
    content: '\e980';
  }

  .custom-control .custom-control-input:checked ~ .custom-control-label::after {
    opacity: 1;
  }

  .custom-control .custom-control-input:disabled ~ .custom-control-label,
  .custom-control .custom-control-input[disabled] ~ .custom-control-label {
    opacity: 0.7;
    color: #364a63;
  }

  .custom-control-sm {
    min-height: 1.125rem;
    padding-left: 1.625rem;
  }

  .custom-control-sm.notext {
    margin-left: -0.5rem;
  }

  .custom-control-sm.notext .custom-control-label {
    left: 0.5rem;
  }

  .custom-control-sm .custom-control-label {
    font-size: 12px;
    line-height: 1.125rem;
    padding-top: 0;
  }

  .custom-control-sm .custom-control-label::after,
  .custom-control-sm .custom-control-label::before {
    left: -1.625rem;
    width: 1.125rem;
    height: 1.125rem;
  }

  .custom-control-sm .custom-control-label:after {
    font-size: 0.9em;
  }

  .custom-control-lg {
    min-height: 2rem;
    padding-left: 3rem;
  }

  .custom-control-lg.notext {
    margin-left: -1rem;
  }

  .custom-control-lg.notext .custom-control-label {
    left: 1rem;
  }

  .custom-control-lg .custom-control-label {
    font-size: 15px;
    line-height: 1.375rem;
    padding-top: 0.3125rem;
  }

  .custom-control-lg .custom-control-label::after,
  .custom-control-lg .custom-control-label::before {
    left: -3rem;
    width: 2rem;
    height: 2rem;
  }

  .custom-control-lg .custom-control-label:after {
    font-size: 1.2em;
  }

  .custom-control-input {
    position: absolute;
    height: 1px;
    width: 1px;
    opacity: 0;
  }

  .custom-control.is-right {
    padding-left: 0;
    padding-right: 2.25rem;
  }

  .custom-control.is-right .custom-control-label:after,
  .custom-control.is-right .custom-control-label:before {
    left: auto;
    right: -2.25rem;
  }

  .custom-control.is-right.custom-control-sm {
    padding-right: 1.625rem;
  }

  .custom-control.is-right.custom-control-sm .custom-control-label:after,
  .custom-control.is-right.custom-control-sm .custom-control-label:before {
    right: -1.625rem;
  }

  .custom-control.is-right.custom-control-lg {
    padding-right: 3rem;
  }

  .custom-control.is-right.custom-control-lg .custom-control-label:after,
  .custom-control.is-right.custom-control-lg .custom-control-label:before {
    right: -3rem;
  }

  .custom-file-label {
    white-space: nowrap;
    overflow: hidden;
  }

  .custom-select,
  div.dataTables_wrapper div.dataTables_length select {
    background: #fff;
  }

  .custom-control-group {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -0.375rem;
  }

  .custom-control-group > * {
    padding: 0.375rem;
  }

  .custom-control-stacked {
    margin: 0;
  }

  .custom-control-stacked > * {
    padding: 0;
    margin: -1px;
  }

  .custom-control-stacked:not(.custom-control-vertical)
    > :not(:first-child)
    .custom-control-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .custom-control-stacked:not(.custom-control-vertical)
    > :not(:last-child)
    .custom-control-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .custom-control-vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-control-vertical > * {
    width: 100%;
  }

  .custom-control-vertical .custom-control {
    display: flex;
  }

  .custom-control-vertical .custom-control .custom-control-label {
    width: 100%;
  }

  .custom-control-vertical.custom-control-stacked
    > :not(:first-child)
    .custom-control-label {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .custom-control-vertical.custom-control-stacked
    > :not(:last-child)
    .custom-control-label {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .custom-control-pro {
    padding-left: 0;
    position: relative;
  }

  .custom-control-pro.no-control.checked {
    z-index: 3;
  }

  .custom-control-pro.no-control.focused {
    z-index: 2;
  }

  .custom-control-pro.no-control .custom-control-label {
    padding: 0.375rem 1.125rem;
    border-width: 2px;
  }

  .custom-control-pro.no-control
    .custom-control-input:checked
    ~ .custom-control-label {
    border-color: var(--primary);
    box-shadow: none;
    z-index: 2;
  }

  .custom-control-pro.no-control
    .custom-control-input:not(:disabled):active
    ~ .custom-control-label {
    border-color: var(--primary);
    box-shadow: none;
  }

  .custom-control-pro.no-control
    .custom-control-input:disabled
    ~ .custom-control-label,
  .custom-control-pro.no-control
    .custom-control-input[disabled]
    ~ .custom-control-label {
    color: #3c4d62;
  }

  .custom-control-pro-block.custom-control {
    display: flex;
  }

  .custom-control-pro-block .custom-control-label {
    width: 100%;
  }

  .custom-control-pro .custom-control-label {
    border: 1px solid #e5e9f2;
    padding: 1.125rem 1.125rem 1.125rem 3.375rem;
    font-size: 13px;
    line-height: 1.25rem;
    border-radius: 4px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    display: inline-flex;
    align-items: center;
    background-color: #fff;
  }

  .custom-control-pro .custom-control-label::after,
  .custom-control-pro .custom-control-label::before {
    top: 50%;
    transform: translateY(-50%);
    left: 1.125rem;
  }

  .custom-control-pro .custom-control-label .icon {
    font-size: 1.4em;
    line-height: inherit;
  }

  .custom-control-pro .custom-control-label .icon-lg {
    font-size: 2.2em;
  }

  .custom-control-pro .custom-control-label > span {
    white-space: nowrap;
  }

  .custom-control-pro .custom-control-label > span:only-child {
    width: 100%;
  }

  .custom-control-pro .custom-control-label .icon + span,
  .custom-control-pro .custom-control-label span + .icon {
    padding-left: 8px;
  }

  .custom-control-pro.custom-control-sm {
    padding-left: 0;
  }

  .custom-control-pro.custom-control-sm .custom-control-label {
    padding: 0.6875rem 1.125rem 0.6875rem 3rem;
    font-size: 13px;
    line-height: 1.25rem;
    border-radius: 4px;
  }

  .custom-control-pro.custom-control-sm .custom-control-label::after,
  .custom-control-pro.custom-control-sm .custom-control-label::before {
    top: 50%;
    transform: translateY(-50%);
    left: 1.125rem;
  }

  .custom-control-pro-icon.custom-control-pro .custom-control-label {
    padding-left: 0;
    padding-right: 0;
  }

  .custom-control-pro-icon.custom-control-pro .custom-control-label .icon {
    text-align: center;
    width: 2.125rem;
  }

  .pro-control.custom-control {
    padding-left: 0;
  }

  .pro-control .custom-control-label {
    padding: 1.5rem;
    border-radius: 4px;
    border: 1px solid #e5e9f2;
    overflow: hidden;
    transition: 0.3s ease;
  }

  .pro-control .custom-control-label:after,
  .pro-control .custom-control-label:before {
    z-index: 9;
  }

  .pro-control .custom-control-label::before {
    border-width: 1px;
    top: 1rem;
    left: 1rem;
  }

  .pro-control .custom-control-label::after {
    top: 1rem;
    left: 1rem;
  }

  .pro-control .custom-control-label .icon-wrap {
    height: 36px;
  }

  .pro-control .custom-control-label .icon-wrap img {
    height: 100%;
  }

  .pro-control .custom-control-label .icon-wrap.lg {
    height: 48px;
  }

  .pro-control .custom-control-label .icon-wrap.xl {
    height: 60px;
  }

  .pro-control .custom-control-label .text-center .icon {
    margin-left: auto;
    margin-right: auto;
  }

  .pro-control .custom-control-input:checked ~ .custom-control-label {
    border-color: var(--primary);
  }

  .custom-control-full {
    width: 100%;
    height: 100%;
  }

  .custom-control-full .custom-control-label {
    width: 100%;
    height: 100%;
  }

  .no-control {
    padding-left: 0;
  }

  .no-control .custom-control-label::after,
  .no-control .custom-control-label::before {
    display: none;
  }

  .custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: var(--primary);
  }

  .custom-checkbox
    .custom-control-input:disabled:indeterminate
    ~ .custom-control-label::before {
    background-color: var(--primary);
  }

  .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }

  .custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: var(--primary);
  }

  .image-control.custom-control {
    padding-left: 0;
  }

  .image-control .custom-control-input:checked ~ .custom-control-label:before {
    border-color: #fff;
  }

  .image-control .custom-control-input:checked ~ .custom-control-label img {
    opacity: 0.8;
  }

  .image-control .custom-control-label {
    padding-top: 0;
    border-radius: 4px;
    overflow: hidden;
    background-color: #1f2b3a;
  }

  .image-control .custom-control-label img {
    transition: opacity 0.3s ease;
  }

  .image-control .custom-control-label:after,
  .image-control .custom-control-label:before {
    z-index: 9;
  }

  .image-control .custom-control-label::before {
    border-width: 1px;
    top: 1rem;
    left: 1rem;
  }

  .image-control .custom-control-label::after {
    top: 1rem;
    left: 1rem;
  }

  .image-control:hover .custom-control-label img {
    opacity: 0.8;
  }

  .custom-switch {
    padding-left: 3.75rem;
  }

  .custom-switch .custom-control-label::before {
    left: -3.75rem;
    width: 3rem;
    border-radius: 1.5rem;
    pointer-events: all;
  }

  .custom-switch .custom-control-label::after {
    opacity: 1;
    top: 0.25rem;
    left: -3.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 1.5rem;
    background-color: #dbdfea;
  }

  .custom-switch
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: var(--primary);
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(calc(2rem - 8px));
  }

  .custom-switch.custom-control-sm {
    padding-left: 2.46875rem;
  }

  .custom-switch.custom-control-sm .custom-control-label::before {
    left: -2.46875rem;
    width: 1.96875rem;
    border-radius: 0.984375rem;
  }

  .custom-switch.custom-control-sm .custom-control-label::after {
    top: 0.2375rem;
    left: calc(-2.46875rem + 4px);
    width: 0.65rem;
    height: 0.65rem;
    border-radius: 0.984375rem;
  }

  .custom-switch.custom-control-sm
    .custom-control-input:checked
    ~ .custom-control-label::after {
    transform: translateX(calc(1.31875rem - 8px));
  }

  .custom-switch.custom-control-lg {
    padding-left: 5rem;
  }

  .custom-switch.custom-control-lg .custom-control-label::before {
    left: -5rem;
    width: 4rem;
    border-radius: 2rem;
  }

  .custom-switch.custom-control-lg .custom-control-label::after {
    top: 0.375rem;
    left: calc(-5rem + 6px);
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 2rem;
  }

  .custom-switch.custom-control-lg
    .custom-control-input:checked
    ~ .custom-control-label::after {
    transform: translateX(calc(2.75rem - 12px));
  }

  .custom-switch.is-right {
    padding-left: 0;
    padding-right: 3.75rem;
  }

  .custom-switch.is-right .custom-control-label:after,
  .custom-switch.is-right .custom-control-label:before {
    left: auto;
    right: -2.25rem;
  }

  .custom-switch.is-right .custom-control-label::before {
    right: -3.75rem;
  }

  .custom-switch.is-right .custom-control-label::after {
    right: calc(-3.75rem + 4px);
  }

  .custom-switch.is-right
    .custom-control-input:checked
    ~ .custom-control-label::after {
    transform: translateX(calc(-2rem + 8px));
  }

  .custom-switch.is-right.custom-control-sm {
    padding-right: 2.46875rem;
  }

  .custom-switch.is-right.custom-control-sm .custom-control-label::before {
    right: -2.46875rem;
  }

  .custom-switch.is-right.custom-control-sm .custom-control-label::after {
    right: calc(-2.46875rem + 4px);
  }

  .custom-switch.is-right.custom-control-sm
    .custom-control-input:checked
    ~ .custom-control-label::after {
    transform: translateX(calc(-1.31875rem + 8px));
  }

  .custom-switch.is-right.custom-control-lg {
    padding-right: 5rem;
  }

  .custom-switch.is-right.custom-control-lg .custom-control-label::before {
    right: -5rem;
  }

  .custom-switch.is-right.custom-control-lg .custom-control-label::after {
    right: calc(-5rem + 6px);
  }

  .custom-switch.is-right.custom-control-lg
    .custom-control-input:checked
    ~ .custom-control-label::after {
    transform: translateX(calc(-2.75rem + 12px));
  }

  label {
    cursor: pointer;
  }

  input[type='radio']:checked ~ label {
    cursor: default;
  }

  .custom-control .invalid,
  .dual-listbox .is-alter .dual-listbox__search ~ .invalid,
  .is-alter .dual-listbox .dual-listbox__search ~ .invalid,
  .is-alter .form-control ~ .invalid,
  .is-alter .input-group ~ .invalid,
  .is-alter div.dataTables_wrapper div.dataTables_filter input ~ .invalid,
  div.dataTables_wrapper div.dataTables_filter .is-alter input ~ .invalid {
    position: absolute;
    left: 0;
    color: #fff;
    font-size: 11px;
    line-height: 1;
    bottom: calc(100% + 4px);
    background: #ed756b;
    padding: 0.3rem 0.5rem;
    z-index: 1;
    border-radius: 3px;
    white-space: nowrap;
  }

  .custom-control .invalid:before,
  .dual-listbox .is-alter .dual-listbox__search ~ .invalid:before,
  .is-alter .dual-listbox .dual-listbox__search ~ .invalid:before,
  .is-alter .form-control ~ .invalid:before,
  .is-alter .input-group ~ .invalid:before,
  .is-alter
    div.dataTables_wrapper
    div.dataTables_filter
    input
    ~ .invalid:before,
  div.dataTables_wrapper
    div.dataTables_filter
    .is-alter
    input
    ~ .invalid:before {
    position: absolute;
    content: '';
    height: 0;
    width: 0;
    border-left: 6px solid #ed756b;
    border-right: 6px solid transparent;
    left: 10px;
    border-bottom: 6px solid transparent;
    bottom: -6px;
  }
}

.form-input-icon-left {
  .rbt-input-main {
    padding-left: 2.5rem;
  }
}
