.dialog-radio-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center !important;
  font-size: 16px !important;
  line-height: 30px !important;

  label {
    display: flex !important;
    margin-bottom: 10px !important;
    line-height: 24px !important;
  }
}

.dialog-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
