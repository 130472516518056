.delegation-signature-wrapper {
  h5 {
    text-align: center;
    margin-bottom: 16px;
    padding-left: 0px;
  }

  .signature-circle-draw {
    margin-top: -105px;
    margin-left: -5px;
  }
}

@media (min-width: 768px) {
  .delegation-signature-wrapper {
    background-position: center;

    h5 {
      text-align: left;
      padding-left: 40px;
    }

    .signature-circle-draw {
      margin-top: -130px;
      margin-left: 20px;
    }
  }
}

.signature-wrapper {
  width: 100%;
  height: 220px;
  max-width: 500px;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  box-sizing: content-box;
  position: relative;
}

.signature-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-self: center;
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdfea !important;

  .data-item {
    padding: 0;
    border: none;
  }

  .data-col.data-col-end {
    display: flex;
    margin-left: 0;
    width: 100%;
    justify-content: flex-start;

    ul {
      display: flex;

      li {
        display: flex;
        flex: 0;
        justify-content: center;
        align-items: center;
      }
    }

    .custom-control-label.color-selector-item::after,
    .custom-control-label.color-selector-item::before {
      // move 36px to the right
      left: 0px;
    }

    .custom-control.color-control {
      padding-left: 0;
      padding-right: 4px;
    }
  }
}

.signature-buttons-area {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 24px auto 4px;

  button {
    max-width: 500px;
  }
}

.signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  width: 500px;
  height: 200px;
  border: 1px dashed var(--primary);
  border-radius: 10px;
}
