.fixed-width-percent {
  width: 8ch;
  margin-bottom: 1px;
}

.co-owner-row {
  display: flex;
  align-items: center;
}

.encumbrance-name {
  display: block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
