.pdf--container {
  overflow-y: auto;

  .react-pdf__Page {
    display: flex;
    justify-content: center;
  }

  .react-pdf__Page__canvas {
    background-color: red !important;
    width: 100% !important;
    height: auto !important;
  }
}
