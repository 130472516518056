.pageWithTabs {
  .nav.nav-tabs {
    justify-content: flex-start;

    .nav-item {
      flex: 1 !important;
      padding-right: 0 !important;

      .nav-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .simplebar-content-wrapper,
  .simplebar-offset,
  .simplebar-mask {
    height: 1200px !important;
  }

  .card-aside {
    width: 350px;
  }

  .simplebar-mask,
  .card-inner-group {
    min-height: 100%;
  }
}
