a.dropdown-toggle {

  button {
    padding-right: 12px !important;
  }
}

div.dropdown {
  box-sizing: border-box;
  padding-right: 6px !important;
}