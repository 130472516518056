.number {
  font-weight: 600;
}

.steps ul li h5 {
  border: none;
  padding: 0 0 6px 0;
  letter-spacing: 0.02em;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--primary);
}

ul li.current {
  border-bottom: solid 2px var(--primary);
  transition: all 0.4s;
}

li[role='tab'] {
  cursor: pointer;
}

input[type='file'] {
  line-height: 28px;
}

#society-form {
  .wizard--final-button:hover {
    background-color: #ffffff;
  }
  div[class$='-indicatorContainer'] {
    height: 34px;
  }
  div[class$='-control'] {
    min-height: 36px;
  }
  div[class$='-ValueContainer'] {
    height: 34px;
  }
  div[class$='-singleValue'],
  div[class$='-menu'] {
    font-size: 13px;
    font-weight: 400;
  }
}

.dark-mode #society-form {
  .wizard--final-button:hover {
    background-color: #141c26;
  }
}
