.preview-icon {
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.reload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  /*Adjustasneeded*/
  color: rgba(0, 0, 0, 0.3);
  /*Adjustcolorasneeded*/
  display: none;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.preview-icon:hover .reload-icon {
  display: block;
}

.document-image {
  display: block;
}
