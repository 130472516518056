.nk-left-section {
  .text-block {
    margin: auto;

    p {
      max-width: 600px;
    }
  }
}

.nk-left-section-logo {
  img {
    width: auto;
    max-width: 100%;
    max-height: 50px;
  }
}

.nk-left-section-image {
  max-width: 70%;

  @media (min-width: 768px) {
    max-width: 600px;
  }
}

#stepper-survey-v1 {
  #admin-email {
    padding-right: 130px;
  }
}
