.constitution-ai-section {
  table {
    td {
      padding-top: 8px;
      padding-bottom: 8px;

      input {
        height: 27px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }

    tr.row-with-input {
      td {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }

    tr.row-no-input {
      td {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }

    .row-internal {
      margin: 0;
      width: 100%;
      height: 41px;
      padding: 6px 10px 6px 6px;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      input,
      select {
        height: 27px;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      vertical-align: middle;
    }
  }
}
