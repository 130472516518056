:root {
  --primary: var(--primary);
  --primary-inverted: #ffffff;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--loading-overlay) !important;
  z-index: 9999;
}
.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8rem;
  height: 8rem;
}
/* LOADING PULSE */
.loading-pulse {
  background-color: var(--primary);
  padding: 0.8rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  position: relative;
}
.loading-pulse:after {
  content: '';
  position: absolute;
  background-color: var(--primary);
  opacity: 70%;
  width: 5rem;
  height: 5rem;
  z-index: -1;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 1s ease-out infinite !important;
  -webkit-animation: pulse 1s ease-out infinite !important;
}
.loading-pulse img {
  width: 100%;
}
/* LOADING PULSE INVERTED */
.loading-pulse-inverted {
  background-color: var(--primary-inverted);
  padding: 0.8rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  position: relative;
}
.loading-pulse-inverted:after {
  content: '';
  position: absolute;
  background-color: var(--primary);
  opacity: 70%;
  width: 5rem;
  height: 5rem;
  z-index: -1;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 1s ease-out infinite;
}
.loading-pulse-inverted img {
  width: 100%;
}

@keyframes pulse {
  0% {
    opacity: 100%;
    width: 4.5rem;
    height: 4.5rem;
  }
  5% {
    opacity: 90%;
    width: 4.5rem;
    height: 4.5rem;
  }
  100% {
    opacity: 0;
    width: 8rem;
    height: 8rem;
  }
}
