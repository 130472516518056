.pld-content-item {
  max-height: 60px;

  &.nk-file-item {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .nk-file-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .nk-file-info {
    max-height: 44px !important;
    display: flex !important;
    flex-direction: row;
    align-items: center;
  }

  .nk-file-icon {
    width: 30px !important;
    display: flex !important;
    height: 44px;

    em {
      font-size: 36px;
    }

    .nk-file-content-type {
      align-self: center;
    }
  }

  .nk-file-name {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;

    .text {
      align-self: center;
      height: 44px;
    }
  }
}
