.chart-container {
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  &__bordered {
    border: solid 1px var(--card_bordered);
  }
}

.chart-item {
  padding: 0 10px;
  display: flex;
  justify-content: center;

  .user-card {
    padding: 10px;
    border-radius: 20px;
    min-width: 190px;
    background-color: var(--body_background);
    cursor: pointer;

    &:hover {
      box-shadow: 0 3px 12px 1px var(--box_shadow) !important;
    }

    .limited {
      display: block;
      overflow: hidden; /* Hide overflow */
      white-space: nowrap; /* Prevent from breaking into multiple lines */
      text-overflow: ellipsis; /* Add ellipsis for overflow */
      width: 120px; /* Or whatever width you want */
      margin-bottom: 0;
    }
  }

  .user-avatar {
    width: 32px;
    height: 32px;
    margin-right: 0;
  }

  .user-info {
    height: 32px;
    line-height: 16px;
    margin-left: 10px !important;
  }

  .lead-text {
    font-size: 12px;
  }

  .sub-text {
    font-size: 11px;
  }
}

.reactOrgChart {
  width: 940px;
  overflow-x: auto;
  padding: 40px 20px;
  border-radius: 5px;
  z-index: 1;
  margin: 0;
}

.app-full-width {
  .reactOrgChart {
    width: calc(100vw - 88px);
  }
}

.fading-effect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to right,
    var(--body_background),
    transparent 80px,
    transparent calc(100% - 80px),
    var(--body_background)
  );
  z-index: 2;
  pointer-events: none;
}

.fading-effect-vertical {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    var(--body_background),
    transparent 80px,
    transparent calc(100% - 80px),
    var(--body_background)
  );
  z-index: 2;
  pointer-events: none;
}

.fading-effect-bottom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to top,
    var(--modal_background) 0%,
    transparent 80px
  );
  z-index: 2;
  pointer-events: none;
  width: calc(100% - 24px);
}

/* create media queries */
@media (max-width: 1280px) {
  .reactOrgChart {
    width: 740px;
  }
}

@media (max-width: 1024px) {
  .reactOrgChart {
    width: 740px;
  }
}

@media (max-width: 768px) {
  .reactOrgChart {
    width: 540px;
  }
}
