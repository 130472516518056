#user-menu-dots {
  padding-top: 0;
  border-top: 3px solid var(--primary) !important;

  &.success {
    border-top: 3px solid #00d68f !important;
  }

  a {
    font-size: 13px !important;

    &.user-menu-item-link {
      padding: 0;
    }

    &:hover {
      background-color: none !important;
    }
  }
}
