.progress-bar-wrapper {
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.fade-out {
    animation: fadeOut 1s ease forwards;
    -webkit-animation: fadeOut 1s ease forwards;
  }

  &.fade-in {
    animation: fadeIn 1s ease forwards;
    -webkit-animation: fadeIn 1s ease forwards;
  }
}
