.user-avatar {
  margin-right: 5px;
}

.user-avatar.sm.bg-blue {
  padding: 0 !important;
}

.user-avatar__picture {
  width: 32px;
  height: 32px !important;
  border-radius: 50% !important;
}

.no-spaced {
  .user-avatar {
    margin-right: 0;
    margin-left: -0.5rem;
    width: 36px !important;
    height: 36px !important;

    .user-avatar__picture {
      width: 36px !important;
      height: 36px !important;
      border: 2px solid #fff;
    }
  }
}
