.color-selector-item {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;

  &::before {
    opacity: 0 !important;
  }
}

.input-color-hex {
  margin: 0 2px 2px 2px;

  .form-control-wrap,
  .form-control {
    height: 31px !important;
  }

  .input-group-prepend {
    width: 30px;
  }

  .input-group-text {
    border: none !important;
    padding: 0 11px !important;
    line-height: 31px !important;
  }

  input {
    border-color: rgb(233, 236, 239) !important;
    min-height: 31px;
  }
}

.logo-preview-box {
  width: 240px;
  height: 46px;
  border-radius: 5px;
  border-style: dashed;
  border-width: 2px;
  border-color: #526484;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
