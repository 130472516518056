.modal-body {
  padding: 24px !important;
  font-family: var(--default-font), sans-serif;

  ul.nav {
    font-family: var(--default-font), sans-serif;
  }

  &.no-padding {
    padding: 0 !important;
  }
}

.isSociety {
  display: flex;
  margin-bottom: 0;
}

.isSociety .form-control {
  width: calc(1.125rem + 2px);
  display: inline;
  height: calc(1.125rem + 2px);
  margin-left: 15px;
}

.d-none {
  display: none;
}

.comms-file {
  border: 1px solid transparent;
  padding: 0.4375rem 1.125rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

#operation-comments {
  min-height: 64px;
}

.form-icon {
  z-index: 1;
}
