.wizard__fixed-size {
  height: 600px;
}

.wizard--sidebar {
  li:hover {
    background-color: #6576ff16;
  }
}

.wizard--content {
  flex-direction: column;
  justify-content: space-between;
}

.wizard--content--step {
  height: 516px;
}

.wizard--pagination {
  height: 84px;
}

.wizard--partners-table-wrapper {
  height: 240px;
  overflow-y: auto;
}

.wizard--constitution-table-wrapper {
  height: 306px;
  overflow-y: auto;
}

.wizard--constitution-partner-row {
  cursor: move;
  border-spacing: 2px;
  border: solid 1px #8080801f;
  background-color: #ffffff;

  &:hover {
    background-color: #6576ff16;
  }
}

.wizard--final-button {
  cursor: pointer;

  &:hover {
    background-color: #6576ff16;
    -webkit-box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.05);

    span {
      color: #1b1b1b !important;
      font-weight: 700;
    }
  }
}

#constitution-partners-table {
  th {
    span {
      text-align: center;
      vertical-align: middle;
      height: 24px;
    }
    span:nth-of-type(1) {
      width: 190px;
    }
    span:nth-of-type(2) {
      width: 180px;
    }
    span:nth-of-type(3) {
      width: 130px;
    }
  }

  tbody {
    td:nth-of-type(1) {
      padding-left: 12px;
      width: 40px;
    }
    td:nth-of-type(2) {
      width: 180px;
    }
    td:nth-of-type(3) {
      width: 80px;
    }
    td:nth-of-type(4) {
      width: 130px;
    }
    td:nth-of-type(5) {
      width: 142px;
    }
  }
}
