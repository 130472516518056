.pld-wrapper {
  .nk-content-wrap,
  .nk-block-between {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .nk-block-des {
    padding: 0 50px;
  }

  .nk-block-head {
    padding-bottom: 0;
  }

  .nk-block-head-content {
    button {
      margin-top: 24px;
    }
  }

  section {
    margin-top: 40px;
    text-align: left;
  }

  .nk-file-item {
    background-color: #f6f6f6;
    border: none;
    height: 90px !important;

    &:hover {
      background-color: #e5e5e5;
    }
  }

  .nk-file-item .nk-file-icon {
    display: none;
  }

  .nk-file-name-text {
    .title {
      font-weight: 600;
    }
    .sub-text {
      display: none;
    }
  }
}

.pld-wrapper-details {
  .card.card-bordered {
    border-color: #e5e5e5 !important;
  }
}
