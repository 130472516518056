.tender-chart {
  .analytic-ov {
    @media only screen and (max-width: 1200px) and (min-width: 768px) {
      width: 200px;
    }

    .w-250px {
      margin: 0 auto !important;
      width: 180px !important;
    }

    .mw-100.justify-content-between {
      max-width: 300px !important;
      justify-content: space-around !important;

      div {
        width: 140px !important;
      }
    }
  }
}
