@import './assets/styles/Fonts.scss';
@import './assets/styles/CustomFonts.scss';

*:not(.avoid-default-font *):not(.icon) {
  font-family: var(--default-font) !important;
}

a {
  text-decoration: none !important;
}

body {
  background-color: var(--body_background) !important;
  color: var(--body_color) !important;
}

.app {
  background: var(--body_background);

  .app__container {
    display: flex;

    padding-left: 14px;
    padding-right: 14px;
    padding-top: 100px;

    margin: 0 auto;

    max-width: 1240px;

    .container__nav {
      display: none;
    }
  }
}

@media screen and (min-width: 990px) {
  .app > .app__container > .container__nav {
    display: block;
  }
}

.container__main {
  width: 100%;
}

#under-maintenance-page {
  font-family: Inter, sans-serif !important;
}
