@font-face {
  font-family: 'Nioicon';
  src: url('../fonts/Nioicon.eot?3rpiw1');
  src: url('../fonts/Nioicon.eot?3rpiw1#iefix') format('embedded-opentype'),
    url('../fonts/Nioicon.ttf?3rpiw1') format('truetype'),
    url('../fonts/Nioicon.woff?3rpiw1') format('woff'),
    url('../fonts/Nioicon.svg?3rpiw1#Nioicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ni {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Nioicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1; /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ni-centos:before {
  content: '';
}

.ni-covid:before {
  content: '';
}

.ni-fedora:before {
  content: '';
}

.ni-hot-fill:before {
  content: '';
}

.ni-hot:before {
  content: '';
}

.ni-linux-server:before {
  content: '';
}

.ni-linux:before {
  content: '';
}

.ni-note-add-fill:before {
  content: '';
}

.ni-repeat-fill:before {
  content: '';
}

.ni-tranx-fill:before {
  content: '';
}

.ni-ubuntu:before {
  content: '';
}

.ni-virus:before {
  content: '';
}

.ni-b-chrome:before {
  content: '';
}

.ni-b-edge:before {
  content: '';
}

.ni-b-firefox:before {
  content: '';
}

.ni-b-ie:before {
  content: '';
}

.ni-b-opera:before {
  content: '';
}

.ni-b-safari:before {
  content: '';
}

.ni-b-si:before {
  content: '';
}

.ni-b-uc:before {
  content: '';
}

.ni-brick-fill:before {
  content: '';
}

.ni-brick:before {
  content: '';
}

.ni-col-3s:before {
  content: '';
}

.ni-col-4s:before {
  content: '';
}

.ni-col-2s:before {
  content: '';
}

.ni-comments:before {
  content: '';
}

.ni-dot-sq:before {
  content: '';
}

.ni-dot:before {
  content: '';
}

.ni-footer:before {
  content: '';
}

.ni-header:before {
  content: '';
}

.ni-heading:before {
  content: '';
}

.ni-layout-alt-fill:before {
  content: '';
}

.ni-layout-alt:before {
  content: '';
}

.ni-layout-fill1:before {
  content: '';
}

.ni-layout1:before {
  content: '';
}

.ni-list-index-fill:before {
  content: '';
}

.ni-list-index:before {
  content: '';
}

.ni-list-thumb-alt-fill:before {
  content: '';
}

.ni-list-thumb-alt:before {
  content: '';
}

.ni-list-thumb-fill:before {
  content: '';
}

.ni-list-thumb:before {
  content: '';
}

.ni-masonry-fill:before {
  content: '';
}

.ni-masonry:before {
  content: '';
}

.ni-menu-circled:before {
  content: '';
}

.ni-menu-squared:before {
  content: '';
}

.ni-notice:before {
  content: '';
}

.ni-pen2:before {
  content: '';
}

.ni-propert-blank:before {
  content: '';
}

.ni-property-add:before {
  content: '';
}

.ni-property-alt:before {
  content: '';
}

.ni-property-remove:before {
  content: '';
}

.ni-property:before {
  content: '';
}

.ni-puzzle-fill:before {
  content: '';
}

.ni-puzzle:before {
  content: '';
}

.ni-quote-left:before {
  content: '';
}

.ni-quote-right:before {
  content: '';
}

.ni-row-mix:before {
  content: '';
}

.ni-row-view1:before {
  content: '';
}

.ni-sidebar-r:before {
  content: '';
}

.ni-text2:before {
  content: '';
}

.ni-tile-thumb-fill:before {
  content: '';
}

.ni-tile-thumb:before {
  content: '';
}

.ni-view-col-fill:before {
  content: '';
}

.ni-view-col-sq:before {
  content: '';
}

.ni-view-col:before {
  content: '';
}

.ni-view-col2:before {
  content: '';
}

.ni-view-col3:before {
  content: '';
}

.ni-view-cols-fill:before {
  content: '';
}

.ni-view-cols-sq:before {
  content: '';
}

.ni-view-cols:before {
  content: '';
}

.ni-view-grid-fill:before {
  content: '';
}

.ni-view-grid-sq:before {
  content: '';
}

.ni-view-grid-wd:before {
  content: '';
}

.ni-view-grid:before {
  content: '';
}

.ni-view-grid2-wd:before {
  content: '';
}

.ni-view-grid3-wd:before {
  content: '';
}

.ni-view-group-fill:before {
  content: '';
}

.ni-view-group-wd:before {
  content: '';
}

.ni-view-list-fill:before {
  content: '';
}

.ni-view-list-sq:before {
  content: '';
}

.ni-view-list-wd:before {
  content: '';
}

.ni-view-list:before {
  content: '';
}

.ni-view-panel-fill:before {
  content: '';
}

.ni-view-panel-sq:before {
  content: '';
}

.ni-view-panel:before {
  content: '';
}

.ni-view-row-fill:before {
  content: '';
}

.ni-view-row-sq:before {
  content: '';
}

.ni-view-row-wd:before {
  content: '';
}

.ni-view-row:before {
  content: '';
}

.ni-view-x1:before {
  content: '';
}

.ni-view-x2:before {
  content: '';
}

.ni-view-x3:before {
  content: '';
}

.ni-view-x4:before {
  content: '';
}

.ni-view-x5:before {
  content: '';
}

.ni-view-x6:before {
  content: '';
}

.ni-view-x7:before {
  content: '';
}

.ni-dashlite:before {
  content: '';
}

.ni-dashlite-circle:before {
  content: '';
}

.ni-dashlite-alt:before {
  content: '';
}

.ni-master-card:before {
  content: '';
}

.ni-paypal:before {
  content: '';
}

.ni-visa-alt:before {
  content: '';
}

.ni-coin-eur:before {
  content: '';
}

.ni-coin-gbp:before {
  content: '';
}

.ni-sign-ada-alt:before {
  content: '';
}

.ni-sign-bch-alt:before {
  content: '';
}

.ni-sign-bgp-alt:before {
  content: '';
}

.ni-sign-bnb-alt:before {
  content: '';
}

.ni-sign-brl-alt:before {
  content: '';
}

.ni-sign-btc-alt:before {
  content: '';
}

.ni-sign-cc-alt:before {
  content: '';
}

.ni-sign-cc-alt2:before {
  content: '';
}

.ni-sign-chf-alt:before {
  content: '';
}

.ni-sign-cny-alt:before {
  content: '';
}

.ni-sign-czk-alt:before {
  content: '';
}

.ni-sign-dash-alt:before {
  content: '';
}

.ni-sign-dkk-alt:before {
  content: '';
}

.ni-sign-eos-alt:before {
  content: '';
}

.ni-sign-eth-alt:before {
  content: '';
}

.ni-sign-eur-alt2:before {
  content: '';
}

.ni-sign-euro-alt:before {
  content: '';
}

.ni-sign-gbp-alt2:before {
  content: '';
}

.ni-sign-hkd-alt:before {
  content: '';
}

.ni-sign-idr-alt:before {
  content: '';
}

.ni-sign-inr-alt:before {
  content: '';
}

.ni-sign-jpy-alt:before {
  content: '';
}

.ni-sign-kr-alt:before {
  content: '';
}

.ni-sign-ltc-alt:before {
  content: '';
}

.ni-sign-ltc:before {
  content: '';
}

.ni-sign-mxn-alt:before {
  content: '';
}

.ni-sign-mxr-alt:before {
  content: '';
}

.ni-sign-myr-alt:before {
  content: '';
}

.ni-sign-paypal-alt:before {
  content: '';
}

.ni-sign-paypal-full:before {
  content: '';
}

.ni-sign-php-alt:before {
  content: '';
}

.ni-sign-pln-alt:before {
  content: '';
}

.ni-sign-rub-alt:before {
  content: '';
}

.ni-sign-sek-alt:before {
  content: '';
}

.ni-sign-sgd-alt:before {
  content: '';
}

.ni-sign-kobo-alt:before {
  content: '';
}

.ni-sign-steem-alt:before {
  content: '';
}

.ni-sign-steller-alt:before {
  content: '';
}

.ni-sign-stripe-fulll:before {
  content: '';
}

.ni-sign-thb-alt:before {
  content: '';
}

.ni-sign-trx-alt:before {
  content: '';
}

.ni-sign-try-alt:before {
  content: '';
}

.ni-sign-usd-alt:before {
  content: '';
}

.ni-sign-usd-alt2:before {
  content: '';
}

.ni-sign-usdc-alt:before {
  content: '';
}

.ni-sign-usdt-alt:before {
  content: '';
}

.ni-sign-visa-alt:before {
  content: '';
}

.ni-sign-vnd-alt:before {
  content: '';
}

.ni-sign-waves-alt:before {
  content: '';
}

.ni-sign-xem-alt:before {
  content: '';
}

.ni-sign-xrp-new-alt:before {
  content: '';
}

.ni-sign-xrp-old-alt:before {
  content: '';
}

.ni-sign-zcash-alt:before {
  content: '';
}

.ni-chevron-left:before {
  content: '';
}

.ni-chevron-right:before {
  content: '';
}

.ni-chevron-up:before {
  content: '';
}

.ni-chevron-down:before {
  content: '';
}

.ni-chevron-left-round:before {
  content: '';
}

.ni-chevron-right-round:before {
  content: '';
}

.ni-chevron-up-round:before {
  content: '';
}

.ni-chevron-down-round:before {
  content: '';
}

.ni-chevron-left-round-fill:before {
  content: '';
}

.ni-chevron-right-round-fill:before {
  content: '';
}

.ni-chevron-up-round-fill:before {
  content: '';
}

.ni-chevron-down-round-fill:before {
  content: '';
}

.ni-chevron-left-c:before {
  content: '';
}

.ni-chevron-right-c:before {
  content: '';
}

.ni-chevron-up-c:before {
  content: '';
}

.ni-chevron-down-c:before {
  content: '';
}

.ni-chevron-left-fill-c:before {
  content: '';
}

.ni-chevron-right-fill-c:before {
  content: '';
}

.ni-chevron-up-fill-c:before {
  content: '';
}

.ni-chevron-down-fill-c:before {
  content: '';
}

.ni-chevron-left-circle:before {
  content: '';
}

.ni-chevron-right-circle:before {
  content: '';
}

.ni-chevron-up-circle:before {
  content: '';
}

.ni-chevron-down-circle:before {
  content: '';
}

.ni-chevron-left-circle-fill:before {
  content: '';
}

.ni-chevron-right-circle-fill:before {
  content: '';
}

.ni-chevron-up-circle-fill:before {
  content: '';
}

.ni-chevron-down-circle-fill:before {
  content: '';
}

.ni-caret-left:before {
  content: '';
}

.ni-caret-right:before {
  content: '';
}

.ni-caret-up:before {
  content: '';
}

.ni-caret-down:before {
  content: '';
}

.ni-caret-left-fill:before {
  content: '';
}

.ni-caret-right-fill:before {
  content: '';
}

.ni-caret-up-fill:before {
  content: '';
}

.ni-caret-down-fill:before {
  content: '';
}

.ni-sort:before {
  content: '';
}

.ni-sort-up:before {
  content: '';
}

.ni-sort-down:before {
  content: '';
}

.ni-sort-fill:before {
  content: '';
}

.ni-sort-up-fill:before {
  content: '';
}

.ni-sort-down-fill:before {
  content: '';
}

.ni-sort-v:before {
  content: '';
}

.ni-swap-v:before {
  content: '';
}

.ni-swap:before {
  content: '';
}

.ni-arrow-left-round:before {
  content: '';
}

.ni-arrow-right-round:before {
  content: '';
}

.ni-arrow-up-round:before {
  content: '';
}

.ni-arrow-down-round:before {
  content: '';
}

.ni-arrow-left-round-fill:before {
  content: '';
}

.ni-arrow-right-round-fill:before {
  content: '';
}

.ni-arrow-up-round-fill:before {
  content: '';
}

.ni-arrow-down-round-fill:before {
  content: '';
}

.ni-arrow-left-c:before {
  content: '';
}

.ni-arrow-right-c:before {
  content: '';
}

.ni-arrow-up-c:before {
  content: '';
}

.ni-arrow-down-c:before {
  content: '';
}

.ni-arrow-left-fill-c:before {
  content: '';
}

.ni-arrow-right-fill-c:before {
  content: '';
}

.ni-arrow-up-fill-c:before {
  content: '';
}

.ni-arrow-down-fill-c:before {
  content: '';
}

.ni-arrow-left-circle:before {
  content: '';
}

.ni-arrow-right-circle:before {
  content: '';
}

.ni-arrow-up-circle:before {
  content: '';
}

.ni-arrow-down-circle:before {
  content: '';
}

.ni-arrow-left-circle-fill:before {
  content: '';
}

.ni-arrow-up-circle-fill:before {
  content: '';
}

.ni-arrow-down-circle-fill:before {
  content: '';
}

.ni-arrow-right-circle-fill:before {
  content: '';
}

.ni-chevrons-left:before {
  content: '';
}

.ni-chevrons-right:before {
  content: '';
}

.ni-chevrons-up:before {
  content: '';
}

.ni-chevrons-down:before {
  content: '';
}

.ni-first:before {
  content: '';
}

.ni-last:before {
  content: '';
}

.ni-back-ios:before {
  content: '';
}

.ni-forward-ios:before {
  content: '';
}

.ni-upword-ios:before {
  content: '';
}

.ni-downward-ios:before {
  content: '';
}

.ni-back-alt:before {
  content: '';
}

.ni-forward-alt:before {
  content: '';
}

.ni-upword-alt:before {
  content: '';
}

.ni-downward-alt:before {
  content: '';
}

.ni-back-alt-fill:before {
  content: '';
}

.ni-forward-alt-fill:before {
  content: '';
}

.ni-upword-alt-fill:before {
  content: '';
}

.ni-downward-alt-fill:before {
  content: '';
}

.ni-arrow-long-left:before {
  content: '';
}

.ni-arrow-long-right:before {
  content: '';
}

.ni-arrow-long-up:before {
  content: '';
}

.ni-arrow-long-down:before {
  content: '';
}

.ni-arrow-left:before {
  content: '';
}

.ni-arrow-right:before {
  content: '';
}

.ni-arrow-up:before {
  content: '';
}

.ni-arrow-down:before {
  content: '';
}

.ni-arrow-up-left:before {
  content: '';
}

.ni-arrow-up-right:before {
  content: '';
}

.ni-arrow-down-left:before {
  content: '';
}

.ni-arrow-down-right:before {
  content: '';
}

.ni-arrow-to-left:before {
  content: '';
}

.ni-arrow-to-right:before {
  content: '';
}

.ni-arrow-to-up:before {
  content: '';
}

.ni-arrow-to-down:before {
  content: '';
}

.ni-arrow-from-left:before {
  content: '';
}

.ni-arrow-from-right:before {
  content: '';
}

.ni-arrow-from-up:before {
  content: '';
}

.ni-arrow-from-down:before {
  content: '';
}

.ni-curve-down-left:before {
  content: '';
}

.ni-curve-up-right:before {
  content: '';
}

.ni-curve-up-left:before {
  content: '';
}

.ni-curve-down-right:before {
  content: '';
}

.ni-curve-left-up:before {
  content: '';
}

.ni-curve-right-up:before {
  content: '';
}

.ni-curve-left-down:before {
  content: '';
}

.ni-curve-right-down:before {
  content: '';
}

.ni-back-arrow:before {
  content: '';
}

.ni-forward-arrow:before {
  content: '';
}

.ni-back-arrow-fill:before {
  content: '';
}

.ni-forward-arrow-fill:before {
  content: '';
}

.ni-navigate:before {
  content: '';
}

.ni-navigate-up:before {
  content: '';
}

.ni-navigate-fill:before {
  content: '';
}

.ni-navigate-up-fill:before {
  content: '';
}

.ni-send:before {
  content: '';
}

.ni-send-alt:before {
  content: '';
}

.ni-unfold-less:before {
  content: '';
}

.ni-unfold-more:before {
  content: '';
}

.ni-exchange-v:before {
  content: '';
}

.ni-exchange:before {
  content: '';
}

.ni-expand:before {
  content: '';
}

.ni-shrink:before {
  content: '';
}

.ni-focus:before {
  content: '';
}

.ni-maximize:before {
  content: '';
}

.ni-minimize:before {
  content: '';
}

.ni-maximize-alt:before {
  content: '';
}

.ni-minimize-alt:before {
  content: '';
}

.ni-shuffle:before {
  content: '';
}

.ni-cross-sm:before {
  content: '';
}

.ni-cross:before {
  content: '';
}

.ni-cross-round:before {
  content: '';
}

.ni-cross-circle:before {
  content: '';
}

.ni-cross-c:before {
  content: '';
}

.ni-cross-round-fill:before {
  content: '';
}

.ni-cross-circle-fill:before {
  content: '';
}

.ni-cross-fill-c:before {
  content: '';
}

.ni-na:before {
  content: '';
}

.ni-check:before {
  content: '';
}

.ni-check-thick:before {
  content: '';
}

.ni-done:before {
  content: '';
}

.ni-check-round:before {
  content: '';
}

.ni-check-circle:before {
  content: '';
}

.ni-check-c:before {
  content: '';
}

.ni-check-round-fill:before {
  content: '';
}

.ni-check-circle-fill:before {
  content: '';
}

.ni-check-fill-c:before {
  content: '';
}

.ni-check-circle-cut:before {
  content: '';
}

.ni-check-round-cut:before {
  content: '';
}

.ni-bullet:before {
  content: '';
}

.ni-circle:before {
  content: '';
}

.ni-square:before {
  content: '';
}

.ni-square-c:before {
  content: '';
}

.ni-bullet-fill:before {
  content: '';
}

.ni-circle-fill:before {
  content: '';
}

.ni-square-fill:before {
  content: '';
}

.ni-square-fill-c:before {
  content: '';
}

.ni-plus-sm:before {
  content: '';
}

.ni-minus-sm:before {
  content: '';
}

.ni-plus:before {
  content: '';
}

.ni-minus:before {
  content: '';
}

.ni-plus-round:before {
  content: '';
}

.ni-minus-round:before {
  content: '';
}

.ni-plus-circle:before {
  content: '';
}

.ni-minus-circle:before {
  content: '';
}

.ni-plus-c:before {
  content: '';
}

.ni-minus-c:before {
  content: '';
}

.ni-plus-round-fill:before {
  content: '';
}

.ni-plus-circle-fill:before {
  content: '';
}

.ni-minus-round-fill:before {
  content: '';
}

.ni-minus-circle-fill:before {
  content: '';
}

.ni-plus-fill-c:before {
  content: '';
}

.ni-minus-fill-c:before {
  content: '';
}

.ni-plus-medi:before {
  content: '';
}

.ni-plus-medi-fill:before {
  content: '';
}

.ni-equal-sm:before {
  content: '';
}

.ni-equal:before {
  content: '';
}

.ni-calc:before {
  content: '';
}

.ni-search:before {
  content: '';
}

.ni-zoom-out:before {
  content: '';
}

.ni-zoom-in:before {
  content: '';
}

.ni-play:before {
  content: '';
}

.ni-play-fill:before {
  content: '';
}

.ni-play-circle:before {
  content: '';
}

.ni-play-circle-fill:before {
  content: '';
}

.ni-pause:before {
  content: '';
}

.ni-pause-fill:before {
  content: '';
}

.ni-pause-circle:before {
  content: '';
}

.ni-pause-circle-fill:before {
  content: '';
}

.ni-stop:before {
  content: '';
}

.ni-stop-fill:before {
  content: '';
}

.ni-stop-circle:before {
  content: '';
}

.ni-stop-circle-fill:before {
  content: '';
}

.ni-rewind:before {
  content: '';
}

.ni-forward:before {
  content: '';
}

.ni-rewind-fill:before {
  content: '';
}

.ni-forward-fill:before {
  content: '';
}

.ni-step-back:before {
  content: '';
}

.ni-step-forward:before {
  content: '';
}

.ni-vol-off:before {
  content: '';
}

.ni-vol-no:before {
  content: '';
}

.ni-vol-half:before {
  content: '';
}

.ni-vol:before {
  content: '';
}

.ni-mic:before {
  content: '';
}

.ni-mic-off:before {
  content: '';
}

.ni-video:before {
  content: '';
}

.ni-video-off:before {
  content: '';
}

.ni-video-fill:before {
  content: '';
}

.ni-loader:before {
  content: '';
}

.ni-power:before {
  content: '';
}

.ni-signout:before {
  content: '';
}

.ni-signin:before {
  content: '';
}

.ni-upload:before {
  content: '';
}

.ni-download:before {
  content: '';
}

.ni-alert-circle:before {
  content: '';
}

.ni-alert:before {
  content: '';
}

.ni-caution:before {
  content: '';
}

.ni-report:before {
  content: '';
}

.ni-alert-c:before {
  content: '';
}

.ni-alert-circle-fill:before {
  content: '';
}

.ni-alert-fill:before {
  content: '';
}

.ni-caution-fill:before {
  content: '';
}

.ni-report-fill:before {
  content: '';
}

.ni-alert-fill-c:before {
  content: '';
}

.ni-info-i:before {
  content: '';
}

.ni-info:before {
  content: '';
}

.ni-info-fill:before {
  content: '';
}

.ni-help:before {
  content: '';
}

.ni-help-fill:before {
  content: '';
}

.ni-archived:before {
  content: '';
}

.ni-archive:before {
  content: '';
}

.ni-unarchive:before {
  content: '';
}

.ni-archived-fill:before {
  content: '';
}

.ni-archive-fill:before {
  content: '';
}

.ni-unarchive-fill:before {
  content: '';
}

.ni-bag:before {
  content: '';
}

.ni-bag-fill:before {
  content: '';
}

.ni-bell:before {
  content: '';
}

.ni-bell-off:before {
  content: '';
}

.ni-bell-fill:before {
  content: '';
}

.ni-bell-off-fill:before {
  content: '';
}

.ni-wifi:before {
  content: '';
}

.ni-wifi-off:before {
  content: '';
}

.ni-live:before {
  content: '';
}

.ni-signal:before {
  content: '';
}

.ni-bluetooth:before {
  content: '';
}

.ni-blank-alt:before {
  content: '';
}

.ni-blank:before {
  content: '';
}

.ni-blankf-fill:before {
  content: '';
}

.ni-block-over:before {
  content: '';
}

.ni-book-read:before {
  content: '';
}

.ni-book:before {
  content: '';
}

.ni-book-fill:before {
  content: '';
}

.ni-bulb-fill:before {
  content: '';
}

.ni-bulb:before {
  content: '';
}

.ni-calendar-alt-fill:before {
  content: '';
}

.ni-calendar-alt:before {
  content: '';
}

.ni-calendar-booking-fill:before {
  content: '';
}

.ni-calendar-booking:before {
  content: '';
}

.ni-calendar-check-fill:before {
  content: '';
}

.ni-calendar-check:before {
  content: '';
}

.ni-calendar-fill:before {
  content: '';
}

.ni-calendar:before {
  content: '';
}

.ni-calender-date-fill:before {
  content: '';
}

.ni-calender-date:before {
  content: '';
}

.ni-call:before {
  content: '';
}

.ni-call-alt:before {
  content: '';
}

.ni-call-alt-fill:before {
  content: '';
}

.ni-call-fill:before {
  content: '';
}

.ni-camera-fill:before {
  content: '';
}

.ni-camera:before {
  content: '';
}

.ni-capsule:before {
  content: '';
}

.ni-capsule-fill:before {
  content: '';
}

.ni-cards:before {
  content: '';
}

.ni-cards-fill:before {
  content: '';
}

.ni-cart:before {
  content: '';
}

.ni-cart-fill:before {
  content: '';
}

.ni-cc:before {
  content: '';
}

.ni-cc-alt:before {
  content: '';
}

.ni-cc-alt2:before {
  content: '';
}

.ni-cc-secure:before {
  content: '';
}

.ni-cc-new:before {
  content: '';
}

.ni-cc-off:before {
  content: '';
}

.ni-cc-fill:before {
  content: '';
}

.ni-cc-alt-fill:before {
  content: '';
}

.ni-cc-alt2-fill:before {
  content: '';
}

.ni-cc-secure-fill:before {
  content: '';
}

.ni-msg-circle:before {
  content: '';
}

.ni-chat-circle:before {
  content: '';
}

.ni-msg:before {
  content: '';
}

.ni-chat:before {
  content: '';
}

.ni-question-alt:before {
  content: '';
}

.ni-question:before {
  content: '';
}

.ni-msg-circle-fill:before {
  content: '';
}

.ni-chat-circle-fill:before {
  content: '';
}

.ni-msg-fill:before {
  content: '';
}

.ni-chat-fill:before {
  content: '';
}

.ni-clip-h:before {
  content: '';
}

.ni-clip-v:before {
  content: '';
}

.ni-clip:before {
  content: '';
}

.ni-link-alt:before {
  content: '';
}

.ni-unlink:before {
  content: '';
}

.ni-unlink-alt:before {
  content: '';
}

.ni-link-h:before {
  content: '';
}

.ni-link-v:before {
  content: '';
}

.ni-link:before {
  content: '';
}

.ni-clipboard:before {
  content: '';
}

.ni-clipboad-check:before {
  content: '';
}

.ni-clipboard-fill:before {
  content: '';
}

.ni-clipboad-check-fill:before {
  content: '';
}

.ni-clock:before {
  content: '';
}

.ni-clock-fill:before {
  content: '';
}

.ni-cloud:before {
  content: '';
}

.ni-upload-cloud:before {
  content: '';
}

.ni-download-cloud:before {
  content: '';
}

.ni-cloud-fill:before {
  content: '';
}

.ni-contact:before {
  content: '';
}

.ni-contact-fill:before {
  content: '';
}

.ni-coffee:before {
  content: '';
}

.ni-coffee-fill:before {
  content: '';
}

.ni-box-view:before {
  content: '';
}

.ni-col-view:before {
  content: '';
}

.ni-sidebar:before {
  content: '';
}

.ni-layout:before {
  content: '';
}

.ni-table-view:before {
  content: '';
}

.ni-layout2:before {
  content: '';
}

.ni-row-view:before {
  content: '';
}

.ni-dot-box:before {
  content: '';
}

.ni-layout-fill:before {
  content: '';
}

.ni-box-view-fill:before {
  content: '';
}

.ni-sidebar-fill:before {
  content: '';
}

.ni-table-view-fill:before {
  content: '';
}

.ni-dot-box-fill:before {
  content: '';
}

.ni-template:before {
  content: '';
}

.ni-browser:before {
  content: '';
}

.ni-toolbar:before {
  content: '';
}

.ni-browser-fill:before {
  content: '';
}

.ni-toolbar-fill:before {
  content: '';
}

.ni-template-fill:before {
  content: '';
}

.ni-box:before {
  content: '';
}

.ni-package:before {
  content: '';
}

.ni-layer:before {
  content: '';
}

.ni-layers:before {
  content: '';
}

.ni-panel:before {
  content: '';
}

.ni-server:before {
  content: '';
}

.ni-layer-fill:before {
  content: '';
}

.ni-layers-fill:before {
  content: '';
}

.ni-package-fill:before {
  content: '';
}

.ni-panel-fill:before {
  content: '';
}

.ni-server-fill:before {
  content: '';
}

.ni-color-palette:before {
  content: '';
}

.ni-color-palette-fill:before {
  content: '';
}

.ni-copy:before {
  content: '';
}

.ni-copy-fill:before {
  content: '';
}

.ni-crop-alt:before {
  content: '';
}

.ni-crop:before {
  content: '';
}

.ni-target:before {
  content: '';
}

.ni-crosshair:before {
  content: '';
}

.ni-crosshair-fill:before {
  content: '';
}

.ni-db-fill:before {
  content: '';
}

.ni-db:before {
  content: '';
}

.ni-hard-drive:before {
  content: '';
}

.ni-cpu:before {
  content: '';
}

.ni-disk:before {
  content: '';
}

.ni-pen:before {
  content: '';
}

.ni-edit-alt:before {
  content: '';
}

.ni-pen-fill:before {
  content: '';
}

.ni-edit-alt-fill:before {
  content: '';
}

.ni-pen-alt-fill:before {
  content: '';
}

.ni-edit-fill:before {
  content: '';
}

.ni-edit:before {
  content: '';
}

.ni-external-alt:before {
  content: '';
}

.ni-external:before {
  content: '';
}

.ni-eye-alt:before {
  content: '';
}

.ni-eye-alt-fill:before {
  content: '';
}

.ni-eye:before {
  content: '';
}

.ni-eye-fill:before {
  content: '';
}

.ni-eye-off:before {
  content: '';
}

.ni-eye-off-fill:before {
  content: '';
}

.ni-file:before {
  content: '';
}

.ni-file-minus:before {
  content: '';
}

.ni-file-plus:before {
  content: '';
}

.ni-file-remove:before {
  content: '';
}

.ni-file-check:before {
  content: '';
}

.ni-file-code:before {
  content: '';
}

.ni-file-docs:before {
  content: '';
}

.ni-file-img:before {
  content: '';
}

.ni-file-doc:before {
  content: '';
}

.ni-file-pdf:before {
  content: '';
}

.ni-file-xls:before {
  content: '';
}

.ni-file-zip:before {
  content: '';
}

.ni-file-download:before {
  content: '';
}

.ni-file-text:before {
  content: '';
}

.ni-files:before {
  content: '';
}

.ni-file-fill:before {
  content: '';
}

.ni-file-minus-fill:before {
  content: '';
}

.ni-file-plus-fill:before {
  content: '';
}

.ni-file-remove-fill:before {
  content: '';
}

.ni-file-check-fill:before {
  content: '';
}

.ni-file-text-fill:before {
  content: '';
}

.ni-files-fill:before {
  content: '';
}

.ni-folder:before {
  content: '';
}

.ni-folder-minus:before {
  content: '';
}

.ni-folder-plus:before {
  content: '';
}

.ni-folder-remove:before {
  content: '';
}

.ni-folder-check:before {
  content: '';
}

.ni-folder-list:before {
  content: '';
}

.ni-folders:before {
  content: '';
}

.ni-folder-fill:before {
  content: '';
}

.ni-folders-fill:before {
  content: '';
}

.ni-filter-alt:before {
  content: '';
}

.ni-sort-line:before {
  content: '';
}

.ni-filter-fill:before {
  content: '';
}

.ni-filter:before {
  content: '';
}

.ni-flag:before {
  content: '';
}

.ni-flag-fill:before {
  content: '';
}

.ni-notify:before {
  content: '';
}

.ni-dashboard:before {
  content: '';
}

.ni-dashboard-fill:before {
  content: '';
}

.ni-grid-sq:before {
  content: '';
}

.ni-grid:before {
  content: '';
}

.ni-grid-c:before {
  content: '';
}

.ni-grid-alt:before {
  content: '';
}

.ni-grid-plus:before {
  content: '';
}

.ni-grid-add-c:before {
  content: '';
}

.ni-grid-fill:before {
  content: '';
}

.ni-grid-fill-c:before {
  content: '';
}

.ni-grid-alt-fill:before {
  content: '';
}

.ni-grid-plus-fill:before {
  content: '';
}

.ni-grid-add-fill-c:before {
  content: '';
}

.ni-grid-box-alt-fill:before {
  content: '';
}

.ni-grid-box-alt:before {
  content: '';
}

.ni-grid-box:before {
  content: '';
}

.ni-grid-box-fill:before {
  content: '';
}

.ni-grid-line:before {
  content: '';
}

.ni-menu-alt-left:before {
  content: '';
}

.ni-menu-alt-r:before {
  content: '';
}

.ni-menu-alt:before {
  content: '';
}

.ni-menu-center:before {
  content: '';
}

.ni-menu-left:before {
  content: '';
}

.ni-menu-right:before {
  content: '';
}

.ni-menu:before {
  content: '';
}

.ni-trend-up:before {
  content: '';
}

.ni-trend-down:before {
  content: '';
}

.ni-line-chart-down:before {
  content: '';
}

.ni-line-chart-up:before {
  content: '';
}

.ni-line-chart:before {
  content: '';
}

.ni-bar-chart:before {
  content: '';
}

.ni-bar-chart-alt:before {
  content: '';
}

.ni-chart-up:before {
  content: '';
}

.ni-chart-down:before {
  content: '';
}

.ni-growth:before {
  content: '';
}

.ni-growth-fill:before {
  content: '';
}

.ni-bar-chart-fill:before {
  content: '';
}

.ni-bar-c:before {
  content: '';
}

.ni-bar-fill-c:before {
  content: '';
}

.ni-pie:before {
  content: '';
}

.ni-pie-alt:before {
  content: '';
}

.ni-pie-fill:before {
  content: '';
}

.ni-activity:before {
  content: '';
}

.ni-activity-alt:before {
  content: '';
}

.ni-activity-round:before {
  content: '';
}

.ni-activity-round-fill:before {
  content: '';
}

.ni-meter:before {
  content: '';
}

.ni-speed:before {
  content: '';
}

.ni-happy:before {
  content: '';
}

.ni-sad:before {
  content: '';
}

.ni-meh:before {
  content: '';
}

.ni-happyf-fill:before {
  content: '';
}

.ni-sad-fill:before {
  content: '';
}

.ni-meh-fill:before {
  content: '';
}

.ni-home:before {
  content: '';
}

.ni-home-alt:before {
  content: '';
}

.ni-home-fill:before {
  content: '';
}

.ni-img:before {
  content: '';
}

.ni-img-fill:before {
  content: '';
}

.ni-inbox:before {
  content: '';
}

.ni-inbox-in:before {
  content: '';
}

.ni-inbox-out:before {
  content: '';
}

.ni-inbox-fill:before {
  content: '';
}

.ni-inbox-in-fill:before {
  content: '';
}

.ni-inbox-out-fill:before {
  content: '';
}

.ni-link-group:before {
  content: '';
}

.ni-lock:before {
  content: '';
}

.ni-lock-alt:before {
  content: '';
}

.ni-lock-fill:before {
  content: '';
}

.ni-lock-alt-fill:before {
  content: '';
}

.ni-unlock:before {
  content: '';
}

.ni-unlock-fill:before {
  content: '';
}

.ni-mail:before {
  content: '';
}

.ni-emails:before {
  content: '';
}

.ni-mail-fill:before {
  content: '';
}

.ni-emails-fill:before {
  content: '';
}

.ni-map-pin:before {
  content: '';
}

.ni-location:before {
  content: '';
}

.ni-map:before {
  content: '';
}

.ni-map-pin-fill:before {
  content: '';
}

.ni-list:before {
  content: '';
}

.ni-list-ol:before {
  content: '';
}

.ni-align-center:before {
  content: '';
}

.ni-align-justify:before {
  content: '';
}

.ni-align-left:before {
  content: '';
}

.ni-align-right:before {
  content: '';
}

.ni-list-check:before {
  content: '';
}

.ni-list-round:before {
  content: '';
}

.ni-card-view:before {
  content: '';
}

.ni-list-fill:before {
  content: '';
}

.ni-save:before {
  content: '';
}

.ni-save-fill:before {
  content: '';
}

.ni-move:before {
  content: '';
}

.ni-scissor:before {
  content: '';
}

.ni-text:before {
  content: '';
}

.ni-text-a:before {
  content: '';
}

.ni-bold:before {
  content: '';
}

.ni-italic:before {
  content: '';
}

.ni-underline:before {
  content: '';
}

.ni-percent:before {
  content: '';
}

.ni-at:before {
  content: '';
}

.ni-hash:before {
  content: '';
}

.ni-code:before {
  content: '';
}

.ni-code-download:before {
  content: '';
}

.ni-terminal:before {
  content: '';
}

.ni-cmd:before {
  content: '';
}

.ni-sun:before {
  content: '';
}

.ni-sun-fill:before {
  content: '';
}

.ni-moon-fill:before {
  content: '';
}

.ni-moon:before {
  content: '';
}

.ni-light:before {
  content: '';
}

.ni-light-fill:before {
  content: '';
}

.ni-more-v:before {
  content: '';
}

.ni-more-h:before {
  content: '';
}

.ni-more-h-alt:before {
  content: '';
}

.ni-more-v-alt:before {
  content: '';
}

.ni-music:before {
  content: '';
}

.ni-movie:before {
  content: '';
}

.ni-offer:before {
  content: '';
}

.ni-offer-fill:before {
  content: '';
}

.ni-opt-alt:before {
  content: '';
}

.ni-opt:before {
  content: '';
}

.ni-opt-dot-alt:before {
  content: '';
}

.ni-opt-dot:before {
  content: '';
}

.ni-opt-dot-fill:before {
  content: '';
}

.ni-opt-alt-fill:before {
  content: '';
}

.ni-user-alt:before {
  content: '';
}

.ni-user-alt-fill:before {
  content: '';
}

.ni-user:before {
  content: '';
}

.ni-users:before {
  content: '';
}

.ni-user-add:before {
  content: '';
}

.ni-user-remove:before {
  content: '';
}

.ni-user-check:before {
  content: '';
}

.ni-user-cross:before {
  content: '';
}

.ni-account-setting:before {
  content: '';
}

.ni-account-setting-alt:before {
  content: '';
}

.ni-user-list:before {
  content: '';
}

.ni-user-fill:before {
  content: '';
}

.ni-users-fill:before {
  content: '';
}

.ni-user-add-fill:before {
  content: '';
}

.ni-user-remove-fill:before {
  content: '';
}

.ni-user-check-fill:before {
  content: '';
}

.ni-user-cross-fill:before {
  content: '';
}

.ni-account-setting-fill:before {
  content: '';
}

.ni-user-list-fill:before {
  content: '';
}

.ni-user-circle:before {
  content: '';
}

.ni-user-circle-fill:before {
  content: '';
}

.ni-user-c:before {
  content: '';
}

.ni-user-fill-c:before {
  content: '';
}

.ni-user-round:before {
  content: '';
}

.ni-printer:before {
  content: '';
}

.ni-printer-fill:before {
  content: '';
}

.ni-laptop:before {
  content: '';
}

.ni-monitor:before {
  content: '';
}

.ni-tablet:before {
  content: '';
}

.ni-mobile:before {
  content: '';
}

.ni-undo:before {
  content: '';
}

.ni-redo:before {
  content: '';
}

.ni-reload-alt:before {
  content: '';
}

.ni-reload:before {
  content: '';
}

.ni-regen-alt:before {
  content: '';
}

.ni-regen:before {
  content: '';
}

.ni-invest:before {
  content: '';
}

.ni-histroy:before {
  content: '';
}

.ni-update:before {
  content: '';
}

.ni-repeat:before {
  content: '';
}

.ni-repeat-v:before {
  content: '';
}

.ni-tranx:before {
  content: '';
}

.ni-reply-all:before {
  content: '';
}

.ni-reply:before {
  content: '';
}

.ni-reply-fill:before {
  content: '';
}

.ni-reply-all-fill:before {
  content: '';
}

.ni-notes:before {
  content: '';
}

.ni-note-add:before {
  content: '';
}

.ni-notes-alt:before {
  content: '';
}

.ni-article:before {
  content: '';
}

.ni-text-rich:before {
  content: '';
}

.ni-todo:before {
  content: '';
}

.ni-report-profit:before {
  content: '';
}

.ni-reports-alt:before {
  content: '';
}

.ni-reports:before {
  content: '';
}

.ni-task:before {
  content: '';
}

.ni-note-add-c:before {
  content: '';
}

.ni-task-c:before {
  content: '';
}

.ni-todo-fill:before {
  content: '';
}

.ni-note-add-fill-c:before {
  content: '';
}

.ni-task-fill-c:before {
  content: '';
}

.ni-scan-fill:before {
  content: '';
}

.ni-scan:before {
  content: '';
}

.ni-qr:before {
  content: '';
}

.ni-money:before {
  content: '';
}

.ni-coins:before {
  content: '';
}

.ni-coin:before {
  content: '';
}

.ni-coin-alt:before {
  content: '';
}

.ni-coin-alt-fill:before {
  content: '';
}

.ni-setting-alt-fill:before {
  content: '';
}

.ni-setting-alt:before {
  content: '';
}

.ni-setting-fill:before {
  content: '';
}

.ni-setting:before {
  content: '';
}

.ni-share-alt:before {
  content: '';
}

.ni-share-fill:before {
  content: '';
}

.ni-share:before {
  content: '';
}

.ni-network:before {
  content: '';
}

.ni-rss:before {
  content: '';
}

.ni-shield:before {
  content: '';
}

.ni-shield-star:before {
  content: '';
}

.ni-shield-check:before {
  content: '';
}

.ni-shield-alert:before {
  content: '';
}

.ni-shield-off:before {
  content: '';
}

.ni-security:before {
  content: '';
}

.ni-policy:before {
  content: '';
}

.ni-shield-alert-fill:before {
  content: '';
}

.ni-shield-check-fill:before {
  content: '';
}

.ni-shield-fill:before {
  content: '';
}

.ni-shield-half:before {
  content: '';
}

.ni-shield-star-fill:before {
  content: '';
}

.ni-policy-fill:before {
  content: '';
}

.ni-spark:before {
  content: '';
}

.ni-spark-off:before {
  content: '';
}

.ni-spark-fill:before {
  content: '';
}

.ni-spark-off-fill:before {
  content: '';
}

.ni-wallet:before {
  content: '';
}

.ni-wallet-alt:before {
  content: '';
}

.ni-wallet-in:before {
  content: '';
}

.ni-wallet-out:before {
  content: '';
}

.ni-wallet-saving:before {
  content: '';
}

.ni-wallet-fill:before {
  content: '';
}

.ni-star:before {
  content: '';
}

.ni-star-half:before {
  content: '';
}

.ni-star-half-fill:before {
  content: '';
}

.ni-star-fill:before {
  content: '';
}

.ni-star-round:before {
  content: '';
}

.ni-heart:before {
  content: '';
}

.ni-heart-fill:before {
  content: '';
}

.ni-swap-alt-fill:before {
  content: '';
}

.ni-swap-alt:before {
  content: '';
}

.ni-thumbs-down:before {
  content: '';
}

.ni-thumbs-up:before {
  content: '';
}

.ni-tag:before {
  content: '';
}

.ni-tag-alt:before {
  content: '';
}

.ni-tags:before {
  content: '';
}

.ni-tag-fill:before {
  content: '';
}

.ni-tag-alt-fill:before {
  content: '';
}

.ni-tags-fill:before {
  content: '';
}

.ni-bookmark:before {
  content: '';
}

.ni-bookmark-fill:before {
  content: '';
}

.ni-label:before {
  content: '';
}

.ni-label-fill:before {
  content: '';
}

.ni-piority:before {
  content: '';
}

.ni-piority-fill:before {
  content: '';
}

.ni-label-alt:before {
  content: '';
}

.ni-label-alt-fill:before {
  content: '';
}

.ni-ticket-alt:before {
  content: '';
}

.ni-ticket:before {
  content: '';
}

.ni-ticket-minus:before {
  content: '';
}

.ni-ticket-plus:before {
  content: '';
}

.ni-ticket-alt-fill:before {
  content: '';
}

.ni-ticket-fill:before {
  content: '';
}

.ni-ticket-minus-fill:before {
  content: '';
}

.ni-ticket-plus-fill:before {
  content: '';
}

.ni-toggle-off:before {
  content: '';
}

.ni-toggle-on:before {
  content: '';
}

.ni-trash-alt:before {
  content: '';
}

.ni-trash-empty:before {
  content: '';
}

.ni-trash:before {
  content: '';
}

.ni-trash-fill:before {
  content: '';
}

.ni-trash-empty-fill:before {
  content: '';
}

.ni-delete-fill:before {
  content: '';
}

.ni-delete:before {
  content: '';
}

.ni-alarm-alt:before {
  content: '';
}

.ni-alarm:before {
  content: '';
}

.ni-bugs:before {
  content: '';
}

.ni-building:before {
  content: '';
}

.ni-building-fill:before {
  content: '';
}

.ni-headphone:before {
  content: '';
}

.ni-headphone-fill:before {
  content: '';
}

.ni-aperture:before {
  content: '';
}

.ni-help-alt:before {
  content: '';
}

.ni-award:before {
  content: '';
}

.ni-briefcase:before {
  content: '';
}

.ni-gift:before {
  content: '';
}

.ni-globe:before {
  content: '';
}

.ni-umbrela:before {
  content: '';
}

.ni-truck:before {
  content: '';
}

.ni-sign-usd:before {
  content: '';
}

.ni-sign-dollar:before {
  content: '';
}

.ni-sign-mxn:before {
  content: '';
}

.ni-sign-sgd:before {
  content: '';
}

.ni-sign-euro:before {
  content: '';
}

.ni-sign-eur:before {
  content: '';
}

.ni-sign-gbp:before {
  content: '';
}

.ni-sign-pound:before {
  content: '';
}

.ni-sign-thb:before {
  content: '';
}

.ni-sign-inr:before {
  content: '';
}

.ni-sign-jpy:before {
  content: '';
}

.ni-sign-yen:before {
  content: '';
}

.ni-sign-cny:before {
  content: '';
}

.ni-sign-kobo:before {
  content: '';
}

.ni-sign-chf:before {
  content: '';
}

.ni-sign-vnd:before {
  content: '';
}

.ni-sign-php:before {
  content: '';
}

.ni-sign-brl:before {
  content: '';
}

.ni-sign-idr:before {
  content: '';
}

.ni-sign-czk:before {
  content: '';
}

.ni-sign-hkd:before {
  content: '';
}

.ni-sign-kr:before {
  content: '';
}

.ni-sign-dkk:before {
  content: '';
}

.ni-sign-nok:before {
  content: '';
}

.ni-sign-sek:before {
  content: '';
}

.ni-sign-rub:before {
  content: '';
}

.ni-sign-myr:before {
  content: '';
}

.ni-sign-pln:before {
  content: '';
}

.ni-sign-try:before {
  content: '';
}

.ni-sign-waves:before {
  content: '';
}

.ni-waves:before {
  content: '';
}

.ni-sign-trx:before {
  content: '';
}

.ni-tron:before {
  content: '';
}

.ni-sign-xem:before {
  content: '';
}

.ni-nem:before {
  content: '';
}

.ni-sign-mxr:before {
  content: '';
}

.ni-monero:before {
  content: '';
}

.ni-sign-usdc:before {
  content: '';
}

.ni-sign-steller:before {
  content: '';
}

.ni-sign-steem:before {
  content: '';
}

.ni-sign-usdt:before {
  content: '';
}

.ni-tether:before {
  content: '';
}

.ni-sign-btc:before {
  content: '';
}

.ni-bitcoin:before {
  content: '';
}

.ni-sign-bch:before {
  content: '';
}

.ni-bitcoin-cash:before {
  content: '';
}

.ni-sign-bnb:before {
  content: '';
}

.ni-binance:before {
  content: '';
}

.ni-sign-ada:before {
  content: '';
}

.ni-sign-zcash:before {
  content: '';
}

.ni-sign-eth:before {
  content: '';
}

.ni-ethereum:before {
  content: '';
}

.ni-sign-dash:before {
  content: '';
}

.ni-dash:before {
  content: '';
}

.ni-sign-xrp-old:before {
  content: '';
}

.ni-ripple-old:before {
  content: '';
}

.ni-sign-eos:before {
  content: '';
}

.ni-eos:before {
  content: '';
}

.ni-sign-xrp:before {
  content: '';
}

.ni-ripple:before {
  content: '';
}

.ni-american-express:before {
  content: '';
}

.ni-jcb:before {
  content: '';
}

.ni-cc-jcb:before {
  content: '';
}

.ni-mc:before {
  content: '';
}

.ni-cc-mc:before {
  content: '';
}

.ni-discover:before {
  content: '';
}

.ni-cc-discover:before {
  content: '';
}

.ni-visa:before {
  content: '';
}

.ni-cc-visa:before {
  content: '';
}

.ni-cc-paypal:before {
  content: '';
}

.ni-cc-stripe:before {
  content: '';
}

.ni-amazon-pay:before {
  content: '';
}

.ni-amazon-pay-fill:before {
  content: '';
}

.ni-google-pay:before {
  content: '';
}

.ni-google-pay-fill:before {
  content: '';
}

.ni-apple-pay:before {
  content: '';
}

.ni-apple-pay-fill:before {
  content: '';
}

.ni-angular:before {
  content: '';
}

.ni-react:before {
  content: '';
}

.ni-laravel:before {
  content: '';
}

.ni-html5:before {
  content: '';
}

.ni-css3-fill:before {
  content: '';
}

.ni-css3:before {
  content: '';
}

.ni-js:before {
  content: '';
}

.ni-php:before {
  content: '';
}

.ni-python:before {
  content: '';
}

.ni-bootstrap:before {
  content: '';
}

.ni-ebay:before {
  content: '';
}

.ni-google-wallet:before {
  content: '';
}

.ni-google-drive:before {
  content: '';
}

.ni-google-play-store:before {
  content: '';
}

.ni-android:before {
  content: '';
}

.ni-blogger-fill:before {
  content: '';
}

.ni-blogger:before {
  content: '';
}

.ni-hangout:before {
  content: '';
}

.ni-apple-store:before {
  content: '';
}

.ni-apple-store-ios:before {
  content: '';
}

.ni-stripe:before {
  content: '';
}

.ni-apple:before {
  content: '';
}

.ni-microsoft:before {
  content: '';
}

.ni-windows:before {
  content: '';
}

.ni-amazon:before {
  content: '';
}

.ni-paypal-alt:before {
  content: '';
}

.ni-airbnb:before {
  content: '';
}

.ni-adobe:before {
  content: '';
}

.ni-mailchimp:before {
  content: '';
}

.ni-dropbox:before {
  content: '';
}

.ni-digital-ocean:before {
  content: '';
}

.ni-slack:before {
  content: '';
}

.ni-slack-hash:before {
  content: '';
}

.ni-stack-overflow:before {
  content: '';
}

.ni-soundcloud:before {
  content: '';
}

.ni-blackberry:before {
  content: '';
}

.ni-spotify:before {
  content: '';
}

.ni-kickstarter:before {
  content: '';
}

.ni-houzz:before {
  content: '';
}

.ni-vine:before {
  content: '';
}

.ni-yelp:before {
  content: '';
}

.ni-yoast:before {
  content: '';
}

.ni-envato:before {
  content: '';
}

.ni-wordpress:before {
  content: '';
}

.ni-wp:before {
  content: '';
}

.ni-wordpress-fill:before {
  content: '';
}

.ni-elementor:before {
  content: '';
}

.ni-joomla:before {
  content: '';
}

.ni-megento:before {
  content: '';
}

.ni-git:before {
  content: '';
}

.ni-github:before {
  content: '';
}

.ni-github-round:before {
  content: '';
}

.ni-github-circle:before {
  content: '';
}

.ni-dribbble:before {
  content: '';
}

.ni-dribbble-round:before {
  content: '';
}

.ni-behance:before {
  content: '';
}

.ni-behance-fill:before {
  content: '';
}

.ni-flickr:before {
  content: '';
}

.ni-flickr-round:before {
  content: '';
}

.ni-medium:before {
  content: '';
}

.ni-medium-round:before {
  content: '';
}

.ni-reddit:before {
  content: '';
}

.ni-reddit-round:before {
  content: '';
}

.ni-reddit-circle:before {
  content: '';
}

.ni-google:before {
  content: '';
}

.ni-facebook-f:before {
  content: '';
}

.ni-facebook-fill:before {
  content: '';
}

.ni-facebook-circle:before {
  content: '';
}

.ni-instagram:before {
  content: '';
}

.ni-instagram-round:before {
  content: '';
}

.ni-linkedin:before {
  content: '';
}

.ni-linkedin-round:before {
  content: '';
}

.ni-twitter:before {
  content: '';
}

.ni-twitter-round:before {
  content: '';
}

.ni-pinterest:before {
  content: '';
}

.ni-pinterest-round:before {
  content: '';
}

.ni-pinterest-circle:before {
  content: '';
}

.ni-tumblr:before {
  content: '';
}

.ni-tumblr-round:before {
  content: '';
}

.ni-skype:before {
  content: '';
}

.ni-viber:before {
  content: '';
}

.ni-whatsapp:before {
  content: '';
}

.ni-whatsapp-round:before {
  content: '';
}

.ni-snapchat:before {
  content: '';
}

.ni-snapchat-fill:before {
  content: '';
}

.ni-telegram:before {
  content: '';
}

.ni-telegram-circle:before {
  content: '';
}

.ni-youtube-line:before {
  content: '';
}

.ni-youtube:before {
  content: '';
}

.ni-youtube-fill:before {
  content: '';
}

.ni-youtube-round:before {
  content: '';
}

.ni-vimeo:before {
  content: '';
}

.ni-vimeo-fill:before {
  content: '';
}

.com {
  color: #93a1a1;
}

.lit {
  color: #195f91;
}

.pun,
.opn,
.clo {
  color: #93a1a1;
}

.fun {
  color: #dc322f;
}

.str,
.atv {
  color: #d14;
}

.kwd,
.linenums .tag {
  color: #1e347b;
}

.typ,
.atn,
.dec,
.var {
  color: teal;
}

.pln {
  color: #48484c;
}

.prettyprint {
  padding: 8px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
}

.prettyprint.linenums {
  -webkit-box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
  -moz-box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
  box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
}
