.loading-spinner {
  .loading-container {
    position: relative; // Ensures the loader is positioned relative to the container
  }

  .loading-overlay {
    position: absolute; // Make the overlay relative to the container
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(
      255,
      255,
      255,
      0.8
    ); // Optional semi-transparent background
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; // Ensure it appears above the content
  }

  .loading-wrapper {
    position: absolute;
  }

  .loading-pulse img {
    max-width: 50px; // Adjust size as needed
  }
}
