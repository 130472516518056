.email-signature-preview {
  display: flex;
  align-items: center;

  color: var(--gray-dark);
  background-color: #fff;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;

  a {
    color: #3f54ff;
    text-decoration: underline !important;
  }

  .signature-container {
    font-family: Arial, sans-serif;
    font-size: 14px;
  }

  .signature-name-jobtitle {
    line-height: 24px;
  }

  .signature-logo {
    width: 150px;
  }

  .signature-line1 {
    line-height: 18px;
    margin-bottom: 2px;
  }

  .signature-line2 {
    line-height: 18px;
    margin-top: 2px;
  }

  .signature-footer {
    color: grey;
    font-size: 10px;
  }
}
