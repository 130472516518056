#send-vote-modal {
  h6 {
    line-height: 24px;
  }

  .error-message-wrapper {
    height: 20px;
  }

  .represented-selector-wrapper {
    height: 74px;
  }

  .vote-input-wrapper {
    .sp-package-price {
      min-width: 200px;
    }
  }

  .instructions-table-wrapper {
    height: 400px;
    overflow-y: auto;

    .placeholder-row {
      height: 500px;
    }

    img {
      width: 200px;
      height: 200px;
    }

    em {
      font-size: 17px;
    }
  }

  .vote-step-text {
    font-size: 14px;
    text-transform: none;
  }

  .icon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-number {
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    color: white;
    pointer-events: none;
  }
}
