.simplebar-content-wrapper,
.simplebar-offset,
.simplebar-mask {
  height: 1200px !important;
}

.card-aside {
  width: 350px;
}

.simplebar-mask,
.card-inner-group {
  min-height: 100%;
}
