.partner-investment-wrapper {
  .card-with-table {
    .card-inner {
      padding: 0;

      .card-title-group {
        padding: 24px 24px 0 24px;
      }

      .nav.nav-tabs.nav-tabs-card {
        margin-left: 0;
        margin-right: 0;
        padding-left: 24px !important;
        padding-right: 24px !important;
      }

      .table tr td:first-child {
        padding-left: 20px !important;
      }
      .table tr td:last-child {
        padding-right: 20px !important;
      }
    }
  }

  [role='tablist'] {
    display: flex;
    justify-content: space-between;
    text-align: center;

    li.nav-item {
      padding-right: 0 !important;
    }

    a {
      font-size: 13px;
    }
  }
}
