.nk-support-item.integration {
  flex-direction: column;

  &.enabled {
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
  }
}

.integration-item {
  display: flex;
  width: 100%;
}

.integration-logo-box {
  box-sizing: border-box;
  height: 70px;
  width: 220px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    max-width: 200px;
    max-height: 50px;
  }
}

.integration-details {
  padding-top: 2rem;
  padding-bottom: 1rem;

  .callout-heading {
    background-color: red !important;
  }
}
