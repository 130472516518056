:root {
  --btn-primary-color: #fff;
  --btn-primary-bg: var(--primary);
  --btn-primary-border: var(--primary);
  --btn-primary-hover-bg: var(--primary-dark);
  --btn-primary-hover-border: var(--primary-darker);
  --btn-primary-focus-shadow: rgba(124, 139, 255, 0.5);

  --btn-secondary-color: #fff;
  --btn-secondary-bg: #364a63;
  --btn-secondary-border: #364a63;
  --btn-secondary-hover-bg: #29384a;
  --btn-secondary-hover-border: #243142;
  --btn-secondary-focus-shadow: rgba(84, 101, 122, 0.5);

  --btn-success-color: #fff;
  --btn-success-bg: #1ee0ac;
  --btn-success-border: #1ee0ac;
  --btn-success-hover-bg: #19be92;
  --btn-success-hover-border: #18b389;
  --btn-success-focus-shadow: rgba(64, 229, 184, 0.5);

  --btn-info-color: #fff;
  --btn-info-bg: #09c2de;
  --btn-info-border: #09c2de;
  --btn-info-hover-bg: #08a2b9;
  --btn-info-hover-border: #0797ad;
  --btn-info-focus-shadow: rgba(46, 203, 227, 0.5);

  --btn-warning-color: #fff;
  --btn-warning-bg: #f4bd0e;
  --btn-warning-border: #f4bd0e;
  --btn-warning-hover-bg: #d2a20a;
  --btn-warning-hover-border: #c69909;
  --btn-warning-focus-shadow: rgba(246, 199, 50, 0.5);

  --btn-danger-color: #fff;
  --btn-danger-bg: #e85347;
  --btn-danger-border: #e85347;
  --btn-danger-hover-bg: #e43325;
  --btn-danger-hover-border: #e02b1c;
  --btn-danger-focus-shadow: rgba(235, 109, 99, 0.5);

  --btn-dark-color: #fff;
  --btn-dark-bg: #1c2b46;
  --btn-dark-border: #1c2b46;
  --btn-dark-hover-bg: #111a2b;
  --btn-dark-hover-border: #0d1522;
  --btn-dark-focus-shadow: rgba(62, 75, 98, 0.5);

  --btn-gray-color: #fff;
  --btn-gray-bg: #8091a7;
  --btn-gray-border: #8091a7;
  --btn-gray-hover-bg: #697d97;
  --btn-gray-hover-border: #647790;
  --btn-gray-focus-shadow: rgba(147, 162, 180, 0.5);

  --btn-light-color: #101924;
  --btn-light-bg: #e5e9f2;
  --btn-light-border: #e5e9f2;
  --btn-light-hover-bg: #ccd3e5;
  --btn-light-hover-border: #c3cce1;
  --btn-light-focus-shadow: rgba(197, 202, 211, 0.5);

  --btn-lighter-color: #101924;
  --btn-lighter-bg: #f5f6fa;
  --btn-lighter-border: #f5f6fa;
  --btn-lighter-hover-bg: #dcdfed;
  --btn-lighter-hover-border: #d3d7e9;
  --btn-lighter-focus-shadow: rgba(211, 213, 218, 0.5);

  --btn-outline-primary-color: var(--primary);
  --btn-outline-primary-border: var(--primary);
  --btn-outline-primary-hover-bg: var(--primary);
  --btn-outline-primary-focus-shadow: rgba(101, 118, 255, 0.5);

  --btn-outline-secondary-color: #364a63;
  --btn-outline-secondary-border: #364a63;
  --btn-outline-secondary-hover-bg: #364a63;
  --btn-outline-secondary-focus-shadow: rgba(54, 74, 99, 0.5);

  --btn-outline-success-color: #1ee0ac;
  --btn-outline-success-border: #1ee0ac;
  --btn-outline-success-hover-bg: #1ee0ac;
  --btn-outline-success-focus-shadow: rgba(30, 224, 172, 0.5);

  --btn-outline-info-color: #09c2de;
  --btn-outline-info-border: #09c2de;
  --btn-outline-info-hover-bg: #09c2de;
  --btn-outline-info-focus-shadow: rgba(9, 194, 222, 0.5);

  --btn-outline-warning-color: #f4bd0e;
  --btn-outline-warning-border: #f4bd0e;
  --btn-outline-warning-hover-bg: #f4bd0e;
  --btn-outline-warning-focus-shadow: rgba(244, 189, 14, 0.5);

  --btn-outline-danger-color: #e85347;
  --btn-outline-danger-border: #e85347;
  --btn-outline-danger-hover-bg: #e85347;
  --btn-outline-danger-focus-shadow: rgba(232, 83, 71, 0.5);

  --btn-outline-dark-color: #1c2b46;
  --btn-outline-dark-border: #1c2b46;
  --btn-outline-dark-hover-bg: #1c2b46;
  --btn-outline-dark-focus-shadow: rgba(28, 43, 70, 0.5);

  --btn-outline-gray-color: #8091a7;
  --btn-outline-gray-border: #8091a7;
  --btn-outline-gray-hover-bg: #8091a7;
  --btn-outline-gray-focus-shadow: rgba(128, 145, 167, 0.5);

  --btn-outline-light-color: #526484;
  --btn-outline-light-border: #e5e9f2;
  --btn-outline-light-hover-bg: #e5e9f2;
  --btn-outline-light-focus-shadow: rgba(229, 233, 242, 0.5);

  --btn-outline-lighter-color: #f5f6fa;
  --btn-outline-lighter-border: #f5f6fa;
  --btn-outline-lighter-hover-bg: #f5f6fa;
  --btn-outline-lighter-focus-shadow: rgba(245, 246, 250, 0.5);
}
