.react-select {
  &.react-select-options__no-wrap {
    div[class$='-option'] {
      text-wrap: nowrap;
    }
  }

  div[class$='-menu'] {
    z-index: 9;
  }

  div[class$='cymwt-control'] {
    color: #3c4d62 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dbdfea;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    div[class$='-singleValue'],
    div[class$='-ValueContainer'] {
      color: #3c4d62 !important;
    }
  }

  div[class$='ipsp-control'] {
    color: #3c4d62;
    background-color: #fff;
    border-color: var(--primary);
    outline: 0;
    box-shadow: 0 0 0 3px rgba(101, 118, 255, 0.1);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    div[class$='-singleValue'],
    div[class$='-ValueContainer'] {
      color: #3c4d62 !important;
    }
  }

  #react-select-2-listbox {
    z-index: 2;
  }
}

.dark-mode .react-select {
  div[class$='cymwt-control'],
  div[class$='ipsp-control'] {
    color: #dbdfea !important;

    div[class$='-singleValue'],
    div[class$='-ValueContainer'] {
      color: #dbdfea !important;
    }
  }
}

.react-select-lg {
  div[class$='ipsp-control'],
  div[class$='cymwt-control'] {
    height: 44px;
  }
}

#board-assistents-modal {
  font-size: 12px !important;
}
