#delegation-box,
#delegation-message-box {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .nk-block-title {
    line-height: 1.75rem;
    margin-bottom: 30px;
  }

  .nk-block-des {
    margin-bottom: 10px;
  }
}
