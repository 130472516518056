@media print {
  .print-only {
    display: flex;
  }

  .screen-only {
    display: none;

    // select all elements inside
    * {
      display: none;
    }
  }

  #margin-print-only {
    padding: 30px 40px 20px !important;
  }
}
