.notification-wrapper {
  margin-top: 65px;

  & + .nk-content {
    padding-top: 0;
  }
}

.notification-box {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.notification-link {
  color: var(--bs-alert-color);
}

.alert-fill .notification-link {
  color: #fff;
}

.alert-fill.alert-light .notification-link {
  color: #364a63;
}

.notification-link {
  text-decoration: underline !important;
}
