.communications-page {
  .inbox-list-wrapper {
    min-height: 640px;
  }

  .inbox-item {
    .nk-ibx-item {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
