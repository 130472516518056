.partner-card {
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;

  .user-card {
    padding-top: 0;
    padding-bottom: 0;
  }

  .user-info h6 {
    color: var(--body_background) !important;
  }

  .btn-trigger .icon {
    color: var(--body_background) !important;
  }

  .team-details {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .card-statistics {
    text-align: center;
    width: 100%;

    h6 {
      font-size: 1rem;
      font-weight: 500;
      display: inline !important;

      div {
        display: inline;
      }
    }

    .partner-values-suffix {
      font-size: 0.75rem !important;
      font-weight: 400 !important;
      display: inline;
    }

    span:first-child {
      font-size: 1.125rem;
      color: #1c2b46;
    }

    // select second span

    span:last-child {
      font-size: 0.875rem;
      color: #8094ae;
      margin-bottom: 12px;
    }
  }

  .header {
    position: relative;
    text-align: center;
    background: linear-gradient(
      60deg,
      rgb(101, 118, 255) 0%,
      rgb(158, 168, 250) 100%
    );
    color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .inner-header {
    height: 100px;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .flex {
    /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .waves {
    position: relative;
    width: 100%;
    height: 75px;
    margin-bottom: -7px; /*Fix for safari gap*/
    padding-bottom: 7px;
    min-height: 75px;
    max-height: 75px;
  }

  .content {
    position: relative;
    height: 200px;
    text-align: center;
    background-color: var(--body_background);
  }

  /* Animation */

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
    .content {
      height: 30vh;
    }
    h1 {
      font-size: 24px;
    }
  }
}
