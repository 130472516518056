.noUi-target {
  background: #6576ff;
}
.noUi-target.noUi-horizontal .noUi-tooltip {
  color: #fff;
  background-color: #6576ff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
