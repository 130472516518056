.simplebar-content-wrapper,
.simplebar-offset,
.simplebar-mask {
  height: 100% !important;
}

.card-aside {
  width: 350px;
}

.simplebar-mask,
.card-inner-group {
  min-height: 100%;
}

#beneficiary-details--tabs.nav-tabs {
  display: flex;
  justify-content: space-between;
  padding-left: 12px !important;
  padding-right: 12px !important;

  .nav-item {
    padding-right: 12px !important;
  }

  .nav-link .icon + span {
    font-size: 12px;
    margin-left: 0.25rem;
  }
}

@media (min-width: 1200px) {
  li.nav-item {
    padding-right: 1.25rem !important;
  }
}
