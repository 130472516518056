// PLD fonts

@font-face {
  font-family: 'PLD';
  font-weight: 100;
  src: url(../fonts/custom/Archivo-Thin.ttf) format('ttf');
}

@font-face {
  font-family: 'PLD';
  font-weight: 200;
  src: url(../fonts/custom/Archivo-ExtraLight.ttf) format('ttf');
}

@font-face {
  font-family: 'PLD';
  font-weight: 300;
  src: url(../fonts/custom/Archivo-Light.ttf) format('ttf');
}

@font-face {
  font-family: 'PLD';
  font-weight: 400;
  src: url(../fonts/custom/Archivo-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'PLD';
  font-weight: 500;
  src: url(../fonts/custom/Archivo-Medium.ttf) format('ttf');
}

@font-face {
  font-family: 'PLD';
  font-weight: 600;
  src: url(../fonts/custom/Archivo-SemiBold.ttf) format('ttf');
}

@font-face {
  font-family: 'PLD';
  font-weight: 700;
  src: url(../fonts/custom/Archivo-Bold.ttf) format('ttf');
}

@font-face {
  font-family: 'PLD';
  font-weight: 800;
  src: url(../fonts/custom/Archivo-ExtraBold.ttf) format('ttf');
}

@font-face {
  font-family: 'PLD';
  font-weight: 900;
  src: url(../fonts/custom/Archivo-Black.ttf) format('ttf');
}
