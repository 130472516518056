#sell-tender-offer {
  ul.sp-package-plan {
    border: 1px solid #dbdfea;
    border-radius: 30px;
    padding: 5px;
  }

  li.nav-item {
    .nav-link {
      color: #8094ae;
      background: #fff;
      padding: 5px 16px !important;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      border-radius: 20px !important;
      -webkit-border-radius: 20px !important;
      -moz-border-radius: 20px !important;
      -ms-border-radius: 20px !important;
      -o-border-radius: 20px !important;

      &.active {
        background: var(--primary);
        color: #fff;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
