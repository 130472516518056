@import './Variables';

/** 03. LAYOUTS */
body {
  min-width: 320px;
}

body.nav-shown {
  overflow: hidden;
}

.nk-body {
  outline: none;
}

.nk-app-root {
  outline: none;
}

.nk-main {
  position: relative;
}

.nk-spinner {
  position: relative;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.nk-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.nk-content {
  padding: 24px 4px;
}

.nk-content-fs {
  min-height: calc(100vh - (65px + 65px));
  display: flex;
  align-items: center;
}

@media (min-width: 576px) {
  .nk-content {
    padding: 32px 22px;
  }
  .nk-content-fluid {
    padding-left: 22px;
    padding-right: 22px;
  }
}

@media (min-width: 992px) {
  .nk-content-lg {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .nk-content-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1660px) {
  .nk-content-lg {
    padding-top: 54px;
    padding-bottom: 54px;
  }
  .nk-content-fluid {
    padding-left: 44px;
    padding-right: 44px;
  }
}

.nk-content-body {
  flex-grow: 1;
}

.nk-wrap-nosidebar .nk-content {
  padding: 0 !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.logo-link {
  position: relative;
  display: inline-block;
  align-items: center;
}

.logo-dark {
  opacity: 1;
}

.tc-light .logo-dark,
.is-dark .logo-dark,
.is-theme .logo-dark {
  opacity: 0;
}

.logo-light {
  opacity: 0;
}

.tc-light .logo-light,
.is-dark .logo-light,
.is-theme .logo-light {
  opacity: 1;
}

.logo-img {
  max-height: 25px;
}

.logo-img-lg {
  max-height: 60px;
}

.logo-img-sm {
  max-height: 28px;
}

.logo-img-icon {
  max-height: 33px;
}

.logo-img:not(:first-child) {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.nk-header {
  background: #f5f6fa;
  border-bottom: 1px solid #e5e9f2;
  padding: 0 6px;
  position: relative;
  z-index: 800;
}

.nk-header.is-dark:not([class*='bg-']) {
  background: #101924;
  border-color: #203247;
}

.nk-header.is-light:not([class*='bg-']) {
  background: #fff;
}

.nk-header.is-theme:not([class*='bg-']) {
  background: #29347a;
  border-color: #3644a0;
}

.nk-header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-width: 320px;
}

.nk-header-fixed + .nk-content {
  margin-top: 25px;
  position: relative;
}

.nk-header-wrap {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 -0.25rem;
}

.nk-header-wrap > * {
  padding: 0 0.25rem;
}

.nk-header-right {
  align-items: center;
  justify-content: flex-end;
}

.nk-header-center {
  justify-content: center;
}

.nk-header-tools {
  margin-left: auto;
  padding-top: 14px;
  padding-bottom: 14px;
}

.nk-header-brand {
  flex-shrink: 0;
  padding-top: 14px;
  padding-bottom: 14px;
}

.nk-header .dropdown-menu .lead-text {
  color: #364a63;
}

.nk-header .dropdown-menu .sub-text,
.nk-header .dropdown-menu .overline-title,
.nk-header .dropdown-menu .overline-title-alt {
  color: #8094ae;
}

.nk-quick-nav {
  display: flex;
  align-items: center;
  margin: 0 -6px;
}

.nk-quick-nav > li {
  padding: 0 6px;
}

.nk-quick-nav > li.user-dropdown > a {
  padding: 0 2px;
}

.nk-quick-nav-icon {
  display: inline-flex;
  position: relative;
  font-size: 1.5rem;
  z-index: 1;
  color: #526484;
  padding: 0.375rem;
}

.is-dark .nk-quick-nav-icon {
  color: #9faec2;
}

.is-theme .nk-quick-nav-icon {
  color: #c4cefe;
}

.is-theme .nk-quick-nav-icon.nk-nav-toggle {
  color: #fff;
}

.nk-quick-nav-icon:focus {
  box-shadow: none;
}

.nk-quick-nav-icon:before {
  position: absolute;
  z-index: -1;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  content: '';
  background-color: #e5e9f2;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s;
}

.is-dark .nk-quick-nav-icon:before {
  background-color: #0b1219;
}

.is-theme .nk-quick-nav-icon:before {
  background-color: #1f275c;
}

.show > .nk-quick-nav-icon:before {
  opacity: 1;
  height: 116%;
  width: 116%;
}

.nk-quick-nav-icon:hover:before,
.nk-quick-nav-icon.active:before {
  opacity: 1;
  height: 116%;
  width: 116%;
}

@media (max-width: 359px) {
  .nk-quick-nav {
    margin: 0 -3px;
  }
  .nk-quick-nav > li {
    padding: 0 3px;
  }
  .hide-mb-xs {
    display: none;
  }
}

@media (max-width: 575px) {
  .hide-mb-sm {
    display: none;
  }
}

@media (min-width: 576px) {
  .nk-header {
    padding: 0 22px;
  }
  .nk-quick-nav {
    margin: 0 -10px;
  }
  .nk-quick-nav > li {
    padding: 0 10px;
  }
}

@media (min-width: 992px) {
  .nk-header-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1660px) {
  .nk-header-fluid {
    padding-left: 44px;
    padding-right: 44px;
  }
}

.nk-header-menu {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 280px;
  height: 100vh;
  max-height: 100vh;
  z-index: 999;
  background: #fff;
}

.nk-header-menu-inner {
  overflow: auto;
  max-height: 100%;
  min-height: 100%;
}

.nk-header-menu.mobile-menu {
  padding-top: 65px;
  padding-left: 0;
  padding-right: 0;
  transition: transform 0.4s ease;
}

.nk-header-menu.nk-sidebar-active {
  transform: translateX(0);
}

.nk-header-mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
  border-bottom: 1px solid #e5e9f2;
}

.nk-header-mobile .logo-dark {
  opacity: 1;
}

.nk-header-mobile .logo-light {
  opacity: 0;
}

.nk-header-mobile .nk-nav-toggle.nk-quick-nav-icon {
  color: #526484;
}

.nk-header-mobile .nk-nav-toggle.nk-quick-nav-icon:before {
  background-color: #e5e9f2;
}

@media (min-width: 992px) {
  .nk-header-menu {
    position: static;
    background: transparent;
    height: auto;
    max-height: auto;
    border-right: none;
    width: auto;
    padding: 0;
    overflow: visible;
    transform: translateX(0) !important;
  }
  .nk-header-menu .nk-header-mobile {
    display: none;
  }
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.nk-aside {
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1px solid #e5e9f2;
  transform: translateX(-100%);
  background: #fff;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 700;
  width: 280px;
  padding-top: 75px;
}

.nk-aside.mobile-menu {
  transition: transform 0.4s ease;
}

.nk-aside.content-active {
  transform: none;
}

.nk-aside + .toggle-overlay {
  z-index: 600;
}

.nk-aside-inner {
  padding-left: 24px;
  padding-right: 24px;
}

.nk-aside-header {
  display: none;
}

.nk-aside-close {
  display: none;
}

.nk-aside .nk-sidebar-menu {
  max-height: calc(100vh - (65px + 24px));
  padding-bottom: 2.5rem;
}

.has-aside .nk-content {
  padding-bottom: 0;
}

.has-aside .nk-content-wrap {
  padding-bottom: 2rem;
}

.has-aside .nk-content-body {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 97px);
}

.has-aside .nk-footer {
  background: transparent;
  padding-top: 0;
  border-top: 0;
  padding-left: 0;
  padding-right: 0;
  margin-left: -1rem;
  margin-right: -1rem;
}

.apps-only .nk-content {
  padding-top: 1.5rem;
  padding-bottom: 0;
}

.apps-only .nk-content-wrap {
  padding-bottom: 1.5rem;
}

.apps-only .nk-footer {
  background: transparent;
  border-top: none;
  padding-left: 0;
  padding-right: 0;
  margin-left: -14px;
  margin-right: -14px;
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .nk-aside {
    width: 300px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 992px) {
  .has-aside .nk-content-inner {
    display: flex;
  }
  .has-aside .nk-content-body {
    padding: 2rem 0 0;
    width: calc(100% - (220px + 48px));
  }
  .nk-aside {
    padding: 2rem 0;
    width: 220px;
    flex-shrink: 0;
    position: static;
    background: transparent;
    opacity: 1;
    visibility: visible;
    margin-right: 48px;
    border: none;
    transform: none;
    max-height: none;
    min-height: none;
  }
  .nk-aside-inner {
    padding-left: 0;
    padding-right: 0;
  }
  .nk-aside .nk-sidebar-menu {
    max-height: none;
  }
  .nk-aside + .toggle-overlay {
    display: none;
  }
  .apps-only .nk-aside {
    display: none;
  }
  .apps-only .nk-content {
    padding-top: 0;
  }
  .apps-only .nk-content-body {
    padding: 1.5rem 0 0;
    width: 100%;
  }
}

@media (max-width: 1199.98px) {
  .nk-aside .nk-menu-main + .nk-menu {
    margin-top: 1.5rem;
  }
}

.nk-menu li a {
  vertical-align: middle;
  display: flex;
  position: relative;
  align-items: center;
  transition: color 0.3s, background-color 0.3s;
}

.nk-menu-item {
  padding: 2px 0;
}

.nk-menu-sub .nk-menu-item {
  padding: 1px 0;
}

.nk-menu-item.has-sub {
  position: relative;
}

.nk-menu > .nk-menu-item.menu-without-icon > .nk-menu-sub .nk-menu-link {
  padding-left: 0;
}

.nk-menu-link {
  padding: 0.625rem 40px 0.625rem 24px;
  color: #6e82a5;
  font-family: var(--default-font), sans-serif;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.01em;
  text-transform: none;
  line-height: 1.25rem;
}

.nk-menu-link:hover,
.active > .nk-menu-link {
  color: var(--primary-light);
}

.nk-menu-link:hover .count,
.active > .nk-menu-link .count {
  color: var(--primary-light);
}

.nk-menu-sub .nk-menu-link {
  padding: 0.375rem 40px 0.375rem 36px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: normal;
  text-transform: none;
  line-height: 1.25rem;
  color: #6e82a5;
}

.nk-menu-link.is-disable {
  cursor: default;
}

.nk-menu-link.is-disable:hover,
.active > .nk-menu-link.is-disable {
  color: #6e82a5;
}

.nk-menu-link.is-disable:hover .count,
.active > .nk-menu-link.is-disable .count {
  color: #6e82a5;
}

.is-light .nk-menu-link {
  color: #526484;
}

.is-theme .nk-menu-link {
  color: #9cadfd;
}

.is-light .nk-menu-link:hover,
.is-light .active > .nk-menu-link {
  color: var(--primary-light);
}

.is-theme .nk-menu-link:hover,
.is-theme .active > .nk-menu-link {
  color: #fff;
}

.nk-menu-link span.small {
  color: #8094ae;
  margin-left: 0.5rem;
}

.nk-menu-icon {
  font-weight: normal;
  letter-spacing: normal;
  width: 36px;
  line-height: 1;
  flex-grow: 0;
  color: #8094ae;
}

.is-theme .nk-menu-icon {
  color: #9cadfd;
}

.nk-menu-icon svg,
.nk-menu-icon img {
  width: 36px;
}

.nk-menu-icon .icon {
  font-size: 24px;
  letter-spacing: normal;
  vertical-align: middle;
  color: currentColor;
  transition: color 0.4s, background-color 0.4s;
}

.nk-menu-link:hover .nk-menu-icon,
.nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon {
  color: var(--primary-light);
}

.is-theme .nk-menu-link:hover .nk-menu-icon,
.is-theme .nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.is-theme .nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon {
  color: #fff;
}

.nk-menu-link.is-disable:hover .nk-menu-icon {
  color: #8094ae;
}

.is-theme .nk-menu-link.is-disable:hover .nk-menu-icon {
  color: #c4cefe;
}

.nk-menu-text {
  flex-grow: 1;
  display: inline-block;
}

.nk-menu-badge {
  border-radius: 3px;
  padding: 0 0.4rem;
  font-size: 11px;
  color: var(--primary-light);
  background: #eff1ff;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}

.is-dark .nk-menu-badge {
  color: #9cabff;
  background: #203247;
}

.is-theme .nk-menu-badge {
  color: #c4cefe;
  background: #3947a8;
}

.nk-menu-main .nk-menu-sub .nk-menu-badge {
  color: var(--primary-light);
  background: #eff1ff;
}

.nk-menu-heading {
  padding: 0.25rem 24px 0.5rem;
  color: #8094ae;
}

.is-theme .nk-menu-heading {
  color: #7990fd;
}

.nk-menu-item + .nk-menu-heading {
  padding-top: 2.5rem;
}

.nk-menu-heading .overline-title {
  color: inherit;
}

.nk-menu-heading span {
  font-size: 13px;
  font-weight: 400;
}

.nk-menu-hr {
  margin: 1.25rem 0;
  border-bottom: 1px solid #dbdfea;
}

.is-dark .nk-menu-hr {
  border-color: rgba(255, 255, 255, 0.12);
}

.is-theme .nk-menu-hr {
  border-color: rgba(255, 255, 255, 0.12);
}

.nk-menu-hr + .nk-menu-heading {
  padding-top: 1rem;
}

.nk-menu-sub {
  padding-bottom: 0.25rem;
}

.nk-menu-toggle + .nk-menu-sub {
  display: none;
}

.active > .nk-menu-sub {
  display: block;
}

.nk-menu-sub .nk-menu-link:hover {
  color: var(--primary);
}

.is-theme .nk-menu-sub .nk-menu-link:hover {
  color: #fff;
}

.nk-menu-sub .active > .nk-menu-link {
  color: var(--primary);
}

.is-theme .nk-menu-sub .active > .nk-menu-link {
  color: #fff;
}

.nk-menu-sub .nk-menu-icon {
  width: 24px;
  margin-top: -1px;
}

.nk-menu-sub .nk-menu-icon .icon {
  margin-top: -1px;
  font-size: 1.2em;
}

.nk-menu-toggle {
  position: relative;
}

.nk-menu-toggle:after {
  position: absolute;
  font-family: 'Nioicon';
  top: 50%;
  right: 1.25rem;
  content: '';
  transform: translateY(-50%);
  font-size: 1rem;
  color: #8094ae;
  transition: transform 0.3s, color 0.1s;
}

.is-dark .nk-menu-toggle:after,
.is-theme .nk-menu-toggle:after {
  color: rgba(255, 255, 255, 0.4);
}

.has-sub.active > .nk-menu-toggle:after {
  transform: translateY(-50%) rotate(90deg);
}

.nk-menu .count {
  display: inline-block;
  font-size: 0.85em;
  margin-left: 0.25rem;
  color: #8094ae;
  line-height: 1;
}

.nk-menu-icon-colored .nk-menu-icon {
  color: var(--primary);
}

.nk-menu-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 992px) {
  .nk-aside .nk-menu > li > .nk-menu-link,
  .nk-aside .nk-menu-heading {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 991.98px) {
  .nk-aside .nk-menu-sub {
    padding-left: 1.5rem;
  }
}

.nk-menu + .nk-menu-sm {
  padding-top: 5rem;
}

.nk-menu-sm .nk-menu-heading {
  padding-bottom: 0.25rem;
  color: #526484;
}

.nk-menu-sm .nk-menu-link {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: #526484;
}

.nk-menu-sm .nk-menu-link:hover {
  color: var(--primary-light);
}

.nk-menu-sm .nk-menu-icon {
  width: 1.375rem;
}

.nk-menu-sm .nk-menu-icon .icon {
  font-size: 1rem;
}

.nk-menu-sm .nk-menu-text {
  font-size: 12px;
  line-height: 1.125rem;
}

.nk-menu + .nk-menu-md {
  padding-top: 5rem;
}

.nk-menu-md .nk-menu-heading {
  padding-bottom: 0.25rem;
  color: #526484;
}

.nk-menu-md .nk-menu-link {
  padding-top: 0.325rem;
  padding-bottom: 0.325rem;
  color: #526484;
}

.nk-menu-md .nk-menu-link:hover {
  color: var(--primary-light);
}

.nk-menu-md .nk-menu-icon {
  width: 1.375rem;
}

.nk-menu-md .nk-menu-icon .icon {
  font-size: 1.25rem;
}

.nk-menu-md .nk-menu-text {
  font-size: 12px;
  line-height: 1.125rem;
}

.nk-menu-inline {
  display: flex;
}

.nk-menu-inline .nk-menu-link {
  padding-left: 0;
  padding-right: 0;
}

.nk-menu-footer {
  display: flex;
}

.nk-menu-footer .nk-menu-link {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: #8094ae;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.nk-menu-footer .nk-menu-link:hover {
  color: var(--primary-light);
}

.is-theme .nk-menu-footer .nk-menu-link {
  color: #c4cefe;
}

.nk-menu-footer .nk-menu-icon {
  width: 1.375rem;
  color: var(--primary);
}

.nk-menu-footer .nk-menu-icon .icon {
  font-size: 1rem;
}

.nk-menu-footer .nk-menu-text {
  font-size: 12px;
  line-height: 1.125rem;
}

.nk-menu-main.nk-menu {
  padding-top: 0.75rem;
}

.nk-menu-main .nk-menu-link {
  color: #526484;
  padding-left: 24px;
}

.nk-menu-main .nk-menu-sub {
  padding: 0;
  margin: 0;
  transition: none;
}

.nk-menu-main .nk-menu-sub .nk-menu-link {
  color: #526484;
}

.nk-menu-main .nk-menu-sub .nk-menu-sub {
  border-left: 1px solid #dbdfea;
  margin: 0.25rem 0 0.25rem 24px;
  padding-bottom: 0;
}

.nk-menu-main .nk-menu-sub .nk-menu-sub .nk-menu-link {
  padding-left: 0.875rem;
}

.nk-menu-main .nk-menu-link:hover,
.nk-menu-main .nk-menu-link:focus,
.nk-menu-main .nk-menu-item.active > .nk-menu-link,
.nk-menu-main .nk-menu-item.current-menu > .nk-menu-link,
.nk-menu-main .nk-menu-item:hover > .nk-menu-link {
  color: var(--primary-light);
}

.nk-menu-main .nk-menu-heading {
  padding: 0.75rem 1.25rem 0.5rem;
}

.nk-menu-main .nk-menu-item + .nk-menu-heading {
  padding-top: 1.25rem;
}

.nk-menu-main > li > .nk-menu-link {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  letter-spacing: -0.01rem;
}

.nk-menu-main.ui-s2 > li > .nk-menu-link {
  font-family: var(--default-font), sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .nk-menu-main.nk-menu {
    max-height: calc(100vh - 65px);
    overflow: auto;
  }
}

@media (min-width: 992px) {
  .nk-menu-main.nk-menu {
    padding: 0 1.5rem 0 2rem;
  }
  .nk-menu-main > li {
    padding: 0;
    display: inline-block;
  }
  .nk-menu-main > li.nk-menu-item {
    padding: 0 1rem;
  }
  .nk-menu-main > li.nk-menu-item > .nk-menu-link {
    padding: 1.25rem 0;
    font-size: 14px;
  }
  .nk-menu-main > li > .nk-menu-link:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: var(--primary-light);
    border-radius: 3px 3px 0 0;
    opacity: 0;
  }
  .nk-menu-main > li.nk-menu-item.active > .nk-menu-link:before,
  .nk-menu-main > li.nk-menu-item.current-menu > .nk-menu-link:before {
    opacity: 1;
  }
  .nk-menu-main > li > .nk-menu-sub {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nk-menu-main .nk-menu-link {
    padding: 0.5rem 1.25rem;
    font-size: 13px;
  }
  .nk-menu-main .nk-menu-icon {
    display: none;
  }
  .nk-menu-main .nk-menu-text {
    line-height: 1.5rem;
  }
  .nk-menu-main .nk-menu-item .nk-menu-toggle:after {
    line-height: 1;
    position: static;
    transform: translateY(0);
    content: '';
    vertical-align: middle;
    color: rgba(128, 148, 174, 0.7);
    margin-left: 0.5rem;
    margin-right: -2px;
    margin-top: -1px;
    transition: color 0.1s;
  }
  .nk-menu-main .nk-menu-item:hover > .nk-menu-sub {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }
  .nk-menu-main .nk-menu-sub {
    position: absolute;
    left: 0;
    top: 100%;
    width: 200px;
    background: #fff;
    border: 1px solid #e5e9f2;
    box-shadow: 0 0.125rem 0.25rem rgba(43, 55, 72, 0.15);
    border-radius: 4px;
    padding: 0.5rem 0;
    opacity: 0;
    visibility: hidden;
    margin-top: 6px;
    transition: opacity 0.4s, margin 0.2s;
    display: block !important;
  }
  .nk-menu-main .nk-menu-sub .nk-menu-link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .nk-menu-main .nk-menu-sub .nk-menu-sub {
    top: -9px;
    padding: 0.5rem 0;
    border: 1px solid #e5e9f2;
    margin: 0;
    margin-top: 6px;
    left: 100%;
    margin-left: -0.75rem;
  }
  .nk-menu-main .nk-menu-sub .nk-menu-sub .nk-menu-link {
    padding-left: 1.5rem;
  }
  .nk-menu-main .nk-menu-sub .nk-menu-item:hover > a {
    background: #f5f6fa;
  }
  .nk-menu-main .nk-menu-sub .nk-menu-toggle:after {
    content: '';
  }
  .nk-aside .nk-menu-main {
    display: none;
  }
  .is-dark .nk-menu-main > li > .nk-menu-link:before,
  .is-theme .nk-menu-main > li > .nk-menu-link:before {
    border-radius: 0;
    bottom: auto;
    top: 0;
  }
  .is-dark .nk-menu-main > li > .nk-menu-link {
    color: #9faec2;
  }
  .is-dark .nk-menu-main > li > .nk-menu-link.nk-menu-toggle:after {
    color: rgba(128, 148, 174, 0.7);
  }
  .is-dark .nk-menu-main > li:hover > .nk-menu-link,
  .is-dark .nk-menu-main > li.active > .nk-menu-link,
  .is-dark .nk-menu-main > li.current-menu > .nk-menu-link,
  .is-dark .nk-menu-main > li > .nk-menu-link:hover {
    color: #fff;
  }
  .is-theme .nk-menu-main > li > .nk-menu-link {
    color: #c4cefe;
  }
  .is-theme .nk-menu-main > li > .nk-menu-link.nk-menu-toggle:after {
    color: rgba(196, 206, 254, 0.7);
  }
  .is-theme .nk-menu-main > li:hover > .nk-menu-link,
  .is-theme .nk-menu-main > li.active > .nk-menu-link,
  .is-theme .nk-menu-main > li.current-menu > .nk-menu-link,
  .is-theme .nk-menu-main > li > .nk-menu-link:hover {
    color: #fff;
  }
}

.nk-footer {
  margin-top: auto;
  background: #fff;
  border-top: 1px solid #e5e9f2;
  padding: 20px 6px;
}

.nk-footer.is-dark:not([class*='bg-']) {
  background: #2b357e;
}

.nk-footer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.nk-footer-copyright {
  color: #8094ae;
}

.nk-footer-copyright a {
  color: inherit;
}

.nk-footer-copyright a:hover {
  color: var(--primary);
}

@media (min-width: 576px) {
  .nk-footer {
    padding: 20px 22px;
  }
}

@media (min-width: 992px) {
  .nk-footer-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1660px) {
  .nk-footer-fluid {
    padding-left: 44px;
    padding-right: 44px;
  }
}

.nk-split {
  position: relative;
  display: flex;
}

.nk-split-page {
  min-height: 100vh;
}

.nk-split-content {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  min-height: 100%;
}

@media (min-width: 768px) {
  .nk-split-content-md {
    width: 642px;
  }
}

.nk-split-stretch {
  flex-grow: 1;
  flex-shrink: 1;
}

@media (max-width: 991.98px) {
  .nk-split-stretch.toggle-break-lg {
    width: 460px;
  }
}

@media (max-width: 575.98px) {
  .nk-split-sm {
    flex-wrap: wrap;
  }
}

@media (max-width: 767.98px) {
  .nk-split-md {
    flex-wrap: wrap;
  }
}

@media (max-width: 991.98px) {
  .nk-split-lg {
    flex-wrap: wrap;
  }
}

@media (max-width: 1199.98px) {
  .nk-split-xl {
    flex-wrap: wrap;
  }
}

@media (max-width: 1539.98px) {
  .nk-split-xxl {
    flex-wrap: wrap;
  }
}
