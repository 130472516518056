#society-modal .modal-body {
  padding: 0 !important;
}

textarea.form-control {
  min-height: 64px !important;
}

input[type='file' i] {
  color: var(--primary);
  background-color: var(--primary);
  border-color: var(--primary);
}

input::-webkit-file-upload-button:link,
input::-webkit-file-upload-button:visited,
input::-webkit-file-upload-button:hover,
input::-webkit-file-upload-button:active
  input::-webkit-file-upload-button:focus {
  border: none !important;
  box-shadow: none !important;
}

input::-webkit-file-upload-button {
  outline: none;
  background-color: var(--primary);
  border: none;
  // opacity: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #fff;
}

input::-webkit-file-upload-button:focus {
  border: red !important;
}

#society-canvas {
  border-radius: 50%;
}

.society-edit-modal-wrapper {
  ul .nav-item {
    .nav-link {
      padding-top: 0;
    }
  }
}
