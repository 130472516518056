.docx--container {
  overflow-x: hidden;
  overflow-y: auto;

  .docx-wrapper {
    background: none;
    padding: 0;
    display: flex;
    justify-content: center;

    section.docx {
      zoom: 0.75;
      padding: 40pt 80pt !important;
      margin-bottom: 0 !important;
    }
  }
}

.doc-preview-box {
  padding: 20px 0px 20px 10px;
  background-color: #ffffff;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}
