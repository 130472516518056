@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

*:not(#ck-editor-instance, #ck-editor-instance *) {
  font-family: 'Roboto', sans-serif;
}

.wrapper-class {
  width: 100%;
  height: 309px;
}

.editor-class {
  width: 100%;
  height: 450px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  h1 {
    font-size: '32px';
  }

  h2 {
    font-size: '24px';
  }

  h3 {
    font-size: '18px';
  }

  h4 {
    font-size: '16px';
  }

  h5 {
    font-size: '14px';
  }

  h6 {
    font-size: '12px';
  }

  p {
    font-size: '10px';
  }

  .public-DraftStyleDefault-block {
    margin: 0.5em 0;
  }

  [data-offset-key],
  [data-text] {
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-color: transparent;
    text-decoration-style: none;
    background-color: white;
    border: none;
    -moz-text-decoration-style: none;
    -moz-text-decoration-color: transparent;
    -moz-text-decoration-line: none;
  }

  .rdw-mention-link {
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-color: transparent;
    text-decoration-style: none;
    background-color: white;
    color: #526484;
    font-weight: 400;
    font-size: 14px;
    -moz-text-decoration-color: transparent;
    -moz-text-decoration-style: none;
  }
}

// change editor-class on md screens
@media (max-width: 1080px) {
  .editor-class {
    height: 400px;
  }
}
