@import '../../../assets/styles/Variables.scss';
@import '../../../assets/styles/CustomThemes.scss';

.page-wrapper {
  &.bg-white {
    .card {
      background-color: var(--body_background_light);
      box-shadow: 0 0 25px -12px rgba(31, 43, 58, 0.15) !important;
    }
  }

  &.bg-lighter {
    .card {
      background-color: var(--body_background);
      box-shadow: 0 0 25px -12px rgba(31, 43, 58, 0.15) !important;
    }

    button.btn-outline-light {
      background-color: white;
    }
  }
}

.dark-mode {
  .page-wrapper {
    .card {
      background-color: var(--modal_background);
      box-shadow: 0 0 25px -12px rgba(31, 43, 58, 0.15) !important;
    }
  }
}

.council-member-basic-info-card {
  .data-item .icon {
    height: initial;
    line-height: initial;
  }

  .data-item a {
    text-decoration: 'none';
    color: 'inherit';
    cursor: 'pointer';
  }

  .data-item.cursor-pointer,
  .nk-file-item.cursor-pointer {
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.02);
      -webkit-transform: scale(1.02);
      -moz-transform: scale(1.02);
      -ms-transform: scale(1.02);
      -o-transform: scale(1.02);
    }
  }
}

.council-recents-section .nk-tb-item.cursor-pointer,
.council-cta-section .card.cursor-pointer {
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -o-transform: scale(1.02);
  }
}

.documents-card-version {
  h6.title {
    font-size: 20px;
  }

  &.card-inner {
    padding: 40px 0 0;

    .nk-files-head {
      display: none;
    }

    // .nk-files-view-list .nk-file:first-child > div:first-child {
    //   border-top-left-radius: 10px;
    // }
    // .nk-files-view-list .nk-file:last-child > div:first-child {
    //   border-bottom-left-radius: 10px;
    // }
    // .nk-files-view-list .nk-file-meta:nth-child(2) {
    //   border-bottom-right-radius: 10px;
    // }

    .nk-files-view-list .nk-file > div:first-child {
      padding-left: 1rem;
    }

    .nk-files-view-list .nk-file:first-child > div {
      border-top: 0;
      border-left: 0;
    }

    .nk-files-view-list .nk-file > div {
      height: 50px;
    }

    .nk-file-icon {
      margin-right: 16px;
    }

    .nk-file-icon-type {
      width: 14px;
    }

    .nk-file-icon img {
      height: 20px;
    }

    .nk-file-name,
    .nk-file-name-text {
      height: 24px;
      line-height: 24px;
    }

    .nk-file-name-text div span {
      color: #526484;
      font-size: 0.9375rem;
    }

    .nk-file-meta {
      display: block;
      font-size: 13px;
      color: #8094ae;
      line-height: 24px;
      height: 24px;
    }

    .nk-file-meta .tb-lead {
      line-height: 32px;
    }

    .nk-file-members .btn-icon {
      padding-top: 0;
      padding-bottom: 0;
    }

    .nk-file-members {
      display: none;
    }

    .nk-files-view-list .nk-files-list:nth-child(2) .nk-file-info {
      border-top-left-radius: 10px;
    }
    .nk-files-view-list .nk-files-list:nth-child(2) .nk-file-meta {
      border-top-right-radius: 10px;
    }
    .nk-files-view-list .nk-files-list:last-of-type .nk-file-info {
      border-bottom-left-radius: 10px;
    }
    .nk-files-view-list .nk-files-list:last-of-type .nk-file-meta {
      border-bottom-right-radius: 10px;
    }
  }
}
