@import './ButtonVariables.scss';

body[custom-theme='default'] {
  --button-radius: 4px;
  --header-wrap-justify-content: normal;
  --header-brand-width: 167px;
  --header-brand-height: 53px;
  --header-brand-padding: 14px 4px;
  --header-tools-margin-left: auto;
  --header-menu-width: auto;
  --header-menu-display: block;
  --header-menu-justify-content: normal;
  --header-menu-text-transform: capitalize;
  --header-menu-text-weight: 400;
  --heading-text-color-light: #364a63;
  --heading-text-color-dark: #ffffff;
  --logo-width: auto;
  --logo-height: 25px;
  --logo-max-width: 100%;
  --logo-max-height: 25px;
  --default-font: Inter, sans-serif;
}

body[custom-theme='pld-space'] {
  --button-radius: 22px;
  --header-wrap-justify-content: space-between;
  --header-brand-width: 203px;
  --header-brand-height: 53px;
  --header-brand-padding: 14px 0px;
  --header-tools-margin-left: 0;
  --header-menu-width: 100%;
  --header-menu-display: flex;
  --header-menu-justify-content: flex-end;
  --header-menu-text-transform: uppercase;
  --header-menu-text-weight: 600;
  --heading-text-color-light: #000000;
  --heading-text-color-dark: #ffffff;
  --logo-width: auto;
  --logo-height: 25px;
  --logo-max-width: 100%;
  --logo-max-height: 25px;
  --default-font: 'PLD', sans-serif;
}

/* CUSTOM THEME CONFIGURATIONS */
body[custom-theme='default'] button,
body[custom-theme='default'] .btn {
  border-radius: var(--button-radius) !important;
  -webkit-border-radius: var(--button-radius) !important;
  -moz-border-radius: var(--button-radius) !important;
  -ms-border-radius: var(--button-radius) !important;
  -o-border-radius: var(--button-radius) !important;

  &.btn-primary {
    background-color: var(--btn-primary-bg);
    border-color: var(--btn-primary-border);
    color: var(--btn-primary-color);
    &:hover {
      background-color: var(--btn-primary-hover-bg);
      border-color: var(--btn-primary-hover-border);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-primary-focus-shadow);
    }
  }

  &.btn-secondary {
    background-color: var(--btn-secondary-bg);
    border-color: var(--btn-secondary-border);
    color: var(--btn-secondary-color);
    &:hover {
      background-color: var(--btn-secondary-hover-bg);
      border-color: var(--btn-secondary-hover-border);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-secondary-focus-shadow);
    }
  }

  &.btn-success {
    background-color: var(--btn-success-bg);
    border-color: var(--btn-success-border);
    color: var(--btn-success-color);
    &:hover {
      background-color: var(--btn-success-hover-bg);
      border-color: var(--btn-success-hover-border);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-success-focus-shadow);
    }
  }

  &.btn-info {
    background-color: var(--btn-info-bg);
    border-color: var(--btn-info-border);
    color: var(--btn-info-color);
    &:hover {
      background-color: var(--btn-info-hover-bg);
      border-color: var(--btn-info-hover-border);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-info-focus-shadow);
    }
  }

  &.btn-warning {
    background-color: var(--btn-warning-bg);
    border-color: var(--btn-warning-border);
    color: var(--btn-warning-color);
    &:hover {
      background-color: var(--btn-warning-hover-bg);
      border-color: var(--btn-warning-hover-border);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-warning-focus-shadow);
    }
  }

  &.btn-danger {
    background-color: var(--btn-danger-bg);
    border-color: var(--btn-danger-border);
    color: var(--btn-danger-color);
    &:hover {
      background-color: var(--btn-danger-hover-bg);
      border-color: var(--btn-danger-hover-border);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-danger-focus-shadow);
    }
  }

  &.btn-dark {
    background-color: var(--btn-dark-bg);
    border-color: var(--btn-dark-border);
    color: var(--btn-dark-color);
    &:hover {
      background-color: var(--btn-dark-hover-bg);
      border-color: var(--btn-dark-hover-border);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-dark-focus-shadow);
    }
  }

  &.btn-gray {
    background-color: var(--btn-gray-bg);
    border-color: var(--btn-gray-border);
    color: var(--btn-gray-color);
    &:hover {
      background-color: var(--btn-gray-hover-bg);
      border-color: var(--btn-gray-hover-border);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-gray-focus-shadow);
    }
  }

  &.btn-light {
    background-color: var(--btn-light-bg);
    border-color: var(--btn-light-border);
    color: var(--btn-light-color);
    &:hover {
      background-color: var(--btn-light-hover-bg);
      border-color: var(--btn-light-hover-border);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-light-focus-shadow);
    }
  }

  &.btn-lighter {
    background-color: var(--btn-lighter-bg);
    border-color: var(--btn-lighter-border);
    color: var(--btn-lighter-color);
    &:hover {
      background-color: var(--btn-lighter-hover-bg);
      border-color: var(--btn-lighter-hover-border);
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-lighter-focus-shadow);
    }
  }

  &.btn-outline-primary {
    color: var(--btn-outline-primary-color);
    border-color: var(--btn-outline-primary-border);
    &:hover {
      background-color: var(--btn-outline-primary-hover-bg);
      border-color: var(--btn-outline-primary-border);
      color: #fff;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-outline-primary-focus-shadow);
    }
  }

  &.btn-outline-secondary {
    color: var(--btn-outline-secondary-color);
    border-color: var(--btn-outline-secondary-border);
    &:hover {
      background-color: var(--btn-outline-secondary-hover-bg);
      border-color: var(--btn-outline-secondary-border);
      color: #fff;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-outline-secondary-focus-shadow);
    }
  }

  &.btn-outline-success {
    color: var(--btn-outline-success-color);
    border-color: var(--btn-outline-success-border);
    &:hover {
      background-color: var(--btn-outline-success-hover-bg);
      border-color: var(--btn-outline-success-border);
      color: #fff;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-outline-success-focus-shadow);
    }
  }

  &.btn-outline-info {
    color: var(--btn-outline-info-color);
    border-color: var(--btn-outline-info-border);
    &:hover {
      background-color: var(--btn-outline-info-hover-bg);
      border-color: var(--btn-outline-info-border);
      color: #fff;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-outline-info-focus-shadow);
    }
  }

  &.btn-outline-warning {
    color: var(--btn-outline-warning-color);
    border-color: var(--btn-outline-warning-border);
    &:hover {
      background-color: var(--btn-outline-warning-hover-bg);
      border-color: var(--btn-outline-warning-border);
      color: #fff;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-outline-warning-focus-shadow);
    }
  }

  &.btn-outline-danger {
    color: var(--btn-outline-danger-color);
    border-color: var(--btn-outline-danger-border);
    &:hover {
      background-color: var(--btn-outline-danger-hover-bg);
      border-color: var(--btn-outline-danger-border);
      color: #fff;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-outline-danger-focus-shadow);
    }
  }

  &.btn-outline-dark {
    color: var(--btn-outline-dark-color);
    border-color: var(--btn-outline-dark-border);
    &:hover {
      background-color: var(--btn-outline-dark-hover-bg);
      border-color: var(--btn-outline-dark-border);
      color: #fff;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-outline-dark-focus-shadow);
    }
  }

  &.btn-outline-gray {
    color: var(--btn-outline-gray-color);
    border-color: var(--btn-outline-gray-border);
    &:hover {
      background-color: var(--btn-outline-gray-hover-bg);
      border-color: var(--btn-outline-gray-border);
      color: #fff;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-outline-gray-focus-shadow);
    }
  }

  &.btn-outline-light {
    color: var(--btn-outline-light-color);
    border-color: var(--btn-outline-light-border);
    &:hover {
      background-color: var(--btn-outline-light-hover-bg);
      border-color: var(--btn-outline-light-border);
      color: #101924;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-outline-light-focus-shadow);
    }
  }

  &.btn-outline-lighter {
    color: var(--btn-outline-lighter-color);
    border-color: var(--btn-outline-lighter-border);
    &:hover {
      background-color: var(--btn-outline-lighter-hover-bg);
      border-color: var(--btn-outline-lighter-border);
      color: #101924;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem var(--btn-outline-lighter-focus-shadow);
    }
  }
}

body[custom-theme='pld-space'] button,
body[custom-theme='pld-space'] .btn {
  border-radius: var(--button-radius) !important;
  -webkit-border-radius: var(--button-radius) !important;
  -moz-border-radius: var(--button-radius) !important;
  -ms-border-radius: var(--button-radius) !important;
  -o-border-radius: var(--button-radius) !important;
}

body[custom-theme='pld-space'][data-theme='dark'] {
  .container__main {
    button.btn,
    a.btn {
      font-weight: 400;
      background-color: #ffffff;
      border-color: #000000;
      color: #000000;
      &:hover {
        background-color: #e5e5e5;
        border-color: #000000;
      }
      &:focus,
      &.focus {
        box-shadow: none;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--heading-text-color-dark);
    }
  }

  .swal2-container {
    button.swal2-confirm {
      font-weight: 400 !important;
      background-color: #ffffff !important;
      border-color: #000000 !important;
      color: #000000 !important;
      &:hover {
        background-color: #e5e5e5 !important;
        border-color: #000000 !important;
      }
      &:focus,
      &.focus {
        box-shadow: none !important;
      }
    }
  }
}

body[custom-theme='pld-space'][data-theme='light'] {
  .container__main {
    button.btn,
    a.btn {
      font-weight: 400;
      background-color: #000000;
      border-color: #ffffff;
      color: #ffffff;
      &:hover {
        background-color: #1e1f22;
        border-color: #ffffff;
      }
      &:focus,
      &.focus {
        box-shadow: none;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--heading-text-color-light);
    }
  }

  .swal2-container {
    button.swal2-confirm {
      font-weight: 400 !important;
      background-color: #000000 !important;
      border-color: #ffffff !important;
      color: #ffffff !important;

      &:hover {
        background-color: #1e1f22 !important;
        border-color: #ffffff !important;
      }
      &:focus,
      &.focus {
        box-shadow: none !important;
      }
    }
  }
}

/* END > CUSTOM THEME CONFIGURATIONS */
